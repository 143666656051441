export const SHORT_NAME = {
    number_of_employees: 'No of Employees',
    industry: 'Industry',
    sub_industry_advanced: 'Sub Industry',
    gross_results: 'Gross results',
    is_ecommerce: 'Is ecommerce',
    has_marketing_department: 'Has mkt department',
    social_media: 'Social media',
    ecommerceCms: 'Ecommerce Cms',
    email_system: 'Email system',
    crm_system: 'CRM system',
    company_type: 'Company type',
    no_unique_visitors_avg_3mo: 'Web traffic',
    platform: 'Platform',
    ads: 'ADS',
    incorporation_date: 'Incorporation date',
    has_analytics_platform: 'Analytics Platform',
    has_website: 'Has Webiste'
};

export function getShortName(key: string, additionalMessage:string = ''): string {
  return additionalMessage + SHORT_NAME[key as keyof typeof SHORT_NAME] || key;
}
