<div class="level-indicator" (click)="toggleLevelUpdateBox()" id="level-indicator-{{filterName}}">
  <div class="text">{{ computedPositions()[filterName] }}</div>
</div>

<div class="level-update level-box-hidden" id="level-update-{{filterName}}">
  <div *ngFor="let pos of computedAvailablePositions()"
        class="text"
        [class.disabled]="!pos.available"
        (click)="handleUpdate(pos.index, filterName)">
    {{ pos.index }}
  </div>
</div>
