export interface FilterMapping {
  source: string,
  dest: string,
  type: MappingType
}

export enum MappingType {
  MULTIVALUED,
  RANGE,
  BOOL,
  CHECKLIST
}