<mat-dialog-content class="confirm">You will lose your funnel configuration, continue?</mat-dialog-content>
<mat-dialog-actions>
  <div class="button-container">
    <button mat-dialog-close class="btn btn-secondary mb-6">
      No
    </button>
    <button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-primary mb-6">
      Yes
    </button>
  </div>
</mat-dialog-actions>
