import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FunnelService } from '../../../services/funnel/funnel.service';
import { ExportFunnelRequestModel } from '../../../models/funnel/export-funnel-request-model';
import { SpinnerService } from '../../../services/core/spinner/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RangeSliderComponent } from '../range-slider/range-slider.component';
import { RegularSliderComponent } from '../regular-slider/regular-slider.component';
import { ExportFunnelResponseModel } from '../../../models/funnel/export-funnel-response-model';
import { MetaService } from '../../../services/meta/meta.service';
import { ExtraFeatureModel } from '../../../models/extra-feature-model';
import { AutoCompleteChipsComponent } from '../auto-complete-chips/auto-complete-chips.component';
import { MultipleSelectComponent } from '../multiple-select/multiple-select.component';


@Component({
  selector: 'app-export-funnel',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    RangeSliderComponent,
    RegularSliderComponent,
    AutoCompleteChipsComponent,
    MultipleSelectComponent
  ],
  templateUrl: './export-funnel.component.html',
  styleUrl: './export-funnel.component.scss',
})
export class ExportFunnelComponent {
  private readonly snackBar = inject(MatSnackBar);
  readonly dialogRef = inject(MatDialogRef<ExportFunnelComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);
  public exportedFunnel!: ExportFunnelResponseModel;
  sampleSize: number = 1000;
  allExtraFeatures: ExtraFeatureModel[] = [];
  extraFeaturesChipValues: string[] = [];
  selectedExtraFeatures: string[] = [];
  availableTiers: { value: number, viewValue: string }[] = [];
  funnelIndexesToExport: number[] = [];

  constructor(
    private readonly funnelService: FunnelService,
    private readonly spinnerService: SpinnerService,
    private readonly metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.metaService.getExtraFeaturesList().subscribe({
      next: (response) => {
        this.allExtraFeatures = response.map((item) => item);
        this.extraFeaturesChipValues = this.allExtraFeatures.map(
          (item) => item.value
        );
      },
      error: (err) => {
        console.error('Error getting extra features:', err);
      }
    });

    this.data.criterias.forEach((criteria: { position: number }) => {
      const hasTier = this.availableTiers.some(
        (tier) => tier.value === criteria.position
      );

      if (!hasTier) {
        this.availableTiers.push({
          value: criteria.position,
          viewValue: `Tier ${criteria.position}`,
        });
      }
    });
  }

  export(): void {
    this.spinnerService.show();
    let request: ExportFunnelRequestModel = {
      funnel_id: this.data._id || '',
      customer_name: '',
      max_sample_size: this.sampleSize,
      funnel_indexes_to_export: this.funnelIndexesToExport,
      delivery_type: 'excel',
      extra_features: this.selectedExtraFeatures,
    };
    this.funnelService.exportFunnel(request).subscribe({
      next: (response: ExportFunnelResponseModel) => {
        this.spinnerService.hide();
        this.exportedFunnel = response;
        this.funnelService.updateCredits();
        window.open(response.sheetLink, '_blank');
        this.close();
      },
      error: (err) => {
        this.spinnerService.hide();
        console.error('Error downloading Leads:', err);
        this.snackBar.open(`Nothing to export`, 'Message', { duration: 5000 });
      },
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  setExtraFeatures(event: string[]): void {
    this.selectedExtraFeatures = this.allExtraFeatures
      .filter((item) => event.includes(item.value))
      .map((item) => item.features)
      .flat();
  }

  setSampleSize(event: any): void {
    const newSampleSize = parseInt(event.value);
    if (!isNaN(newSampleSize)) {
      this.sampleSize = newSampleSize;
    }
  }

  setTiers(event: any[]): void {
    this.funnelIndexesToExport = event;
  }
}
