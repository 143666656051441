<main class="main">
  <div class="container">
    <form [formGroup]="loginForm">
      <div class="content-center">
        <div class="logo-container">
          <img
            src="../../../../assets/images/icons/Hvid_logo 1.png"
            alt="hintly logo"
            referrerpolicy="no-referrer"
            class="logo"
          />
        </div>
        <div class="input-container">
          <input
            type="text"
            id="username"
            placeholder="Id"
            formControlName="username"
            class="input-group mt-3"
            [ngClass]="{ 'is-invalid': loginForm.controls['username'].touched && loginForm.controls['username'].invalid }"
          />
          <div *ngIf="loginForm.controls['username'].touched && loginForm.controls['username'].invalid" class="invalid-feedback">
            Username is required
          </div>
        </div>
        <div class="input-container">
          <input
            type="password"
            id="password"
            placeholder="Password"
            formControlName="password"
            class="input-group mt-3"
            [ngClass]="{ 'is-invalid': loginForm.controls['password'].touched && loginForm.controls['password'].invalid }"
          />
          <div *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].invalid" class="invalid-feedback">
            Password is required
          </div>
        </div>
        <div *ngIf="hasError" class="error-message mt-2">
          {{ errorMessage }}
        </div>
        <div class="button-container mt-3">
          <button (click)="login()" class="btn btn-primary mb-3">
            Login
          </button>
        </div>
      </div>
    </form>
  </div>
</main>