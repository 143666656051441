import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { FunnelItemModel } from '../../../models/funnel-model';
import { AppFunnelTierDirective } from '../../../directives/app-funnel-tier.directive';
import { Router } from '@angular/router';

@Component({
  selector: 'app-funnel',
  standalone: true,
  imports: [CommonModule, AppFunnelTierDirective],
  templateUrl: './funnel.component.html',
  styleUrl: './funnel.component.scss'
})
export class FunnelComponent {
  @Input() id?: string
  @Input() criterias!: FunnelItemModel[];

}
