<div class="treeview" [ngClass]="{ 'one-row': items.length < 2}">
  <ng-container *ngFor="let item of items">
    <div class="parent-item">
      <mat-checkbox
        #checkbox
        class="hintly-checkbox"
        [disabled]="disabled"
        [checked]="selectedItems[item.value] === states.CHECKED"
        [indeterminate]="selectedItems[item.value] == states.OPPOSITE"
        [ngClass]="{ 'opposite-state': selectedItems[item.value] === states.OPPOSITE }"
        (change)="onItemChange(item.value, checkbox)">
        {{ item.label }}
      </mat-checkbox>
    </div>
  </ng-container>
</div>
