import { CommonModule } from '@angular/common';
import { Component, computed, Input, Signal } from '@angular/core';
import { FunnelFormSorting } from '../../../models/funnel-management-models';

@Component({
  selector: 'app-criteria-position',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './criteria-position.component.html',
  styleUrl: './criteria-position.component.scss'
})
export class CriteriaPositionComponent {
  @Input() handleChange!: Function;
  @Input() filterName!: string;
  @Input() positions!: Signal<FunnelFormSorting>;
  @Input() availablePositions!: Signal<{index: number, available: boolean}[]>;
  @Input() disabled: boolean = false;
  computedPositions!: Signal<FunnelFormSorting>;
  computedAvailablePositions!: Signal<{index: number, available: boolean}[]>;

  constructor() {
  }

  ngOnInit() {
    this.computedPositions = computed(() => this.positions());
    this.computedAvailablePositions = computed(() => this.availablePositions());
  }

  toggleLevelUpdateBox() {
    if (this.disabled)
      return;
    const levelIndicator = document.getElementById(`level-indicator-${this.filterName}`);
    const levelUpdate = document.getElementById(`level-update-${this.filterName}`);
    levelIndicator?.classList.toggle('level-box-hidden');
    levelUpdate?.classList.toggle('level-box-hidden');

    window.addEventListener('click', (event) => {
      if (levelIndicator?.classList.contains('level-box-hidden')) {
        if (!levelUpdate?.classList.contains('level-box-hidden')) {
          if (event.target && (event.target as HTMLElement).id !== `level-indicator-${this.filterName}` && (event.target as HTMLElement).id !== `level-update-${this.filterName}`) {
            this.toggleLevelUpdateBox();
          }
        }
      }
    });
  }

  handleUpdate(newIndex: number, filterName: string, available: boolean) {
    if (!available) {
      this.toggleLevelUpdateBox();
      return;
    }

    if (newIndex !== 1) {
      this.handleChange(newIndex, filterName);
    }
    this.toggleLevelUpdateBox();
  }
}
