<div
  class="funnel-container"
  appFunnelTier
  [numberOfCriterias]="criterias.length"
  [funnelId]="id"
>
  @for (criteria of criterias; track criteria) {
    <div class="criteria-tier">
      <span class="number">{{ criteria.counter }}</span>
      <span class="name">{{ criteria.description || 'No Filter' }}</span>
    </div>
  }
</div>
