<div class="funnel-header">
  <div class="title-wrapper">
    <h2 class="title">
      @if (name) {
        {{ name }}
      } @else {
        <span>Funnel name</span>
      }
    </h2>
    @if (funnelItem._id) {
      <div class="actions d-flex justify-content-end align-items-center">
        <span class="exported">
          Exported {{ exportedLeads }}/{{ totalLeads }}
        </span>
        <img
          src="assets/images/icons/tree-dots.svg"
          alt="edit icon"
          [matMenuTriggerFor]="menu"
          class="edit-icon pe-3"
        />
        @if (funnelItem.is_favorite) {
          <img
            src="assets/images/icons/star-selected.svg"
            (click)="favoriteFunnel()"
            alt="star icon"
            class="pe-3"
          />
        } @else {
          <img
            src="assets/images/icons/star-unselected.svg"
            (click)="favoriteFunnel()"
            alt="star icon"
            class="pe-3"
          />
        }
      </div>
    }
  </div>
</div>
<div class="floating-menu">
  <mat-menu #menu="matMenu" class="hintly-floating-menu">
    <button mat-menu-item (click)="openFunnelExport()">
      <img src="assets/images/icons/export.png" alt="export icon" />
      <span>Export funnel</span>
    </button>
    <button mat-menu-item (click)="openFunnelExportHistory()">
      <img src="assets/images/icons/export.png" alt="export icon" />
      <span>Export history</span>
    </button>
  </mat-menu>
</div>
