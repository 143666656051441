<mat-slider
  [disabled]="disabled"
  class="hintly-slider"
  discrete
  (change)="onInputChange()"
  [step]="step"
  [min]="min" [max]="max"
>
  <input [value]="minValue" #start matSliderStartThumb>
  <input [value]="maxValue" #end matSliderEndThumb>
</mat-slider>
<div class="range">
  <div class="min-value">{{ minValue }} {{ pattern }}</div>
  <div class="max-value">{{ maxValue }} {{ pattern }}</div>
</div>
