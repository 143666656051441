<main class="mt-4">
  <div class="title-container w-100 my-4 d-flex justify-content-between">
    <div class="text-wrapper">
      <h2>Funnels</h2>
    </div>
    <div class="buttons-wrapper">
      <div class="image">
        <a routerLink="/funnel-management/" id="create-funnel">
          <div class="button-container">
            <button class="btn btn-primary">
              <img
                src="assets/images/icons/plus.png"
                width="20"
                alt="Create Funnel Icon"
              />
              Create a new funnel
            </button>
          </div>
        </a>
      </div>
    </div>
  </div>
  @if (!funnels) {
    <div class="no-funnel">
      <div>No funnels have been created yet.</div>
      <a routerLink="/funnel-management/">
        <div class="button-container">
          <button class="btn btn-primary">
            Click here to create your first funnel
          </button>
        </div>
      </a>
    </div>
  } @else {
    <div class="funnels-container">
      @for (funnel of funnels; track funnel) {
        <div class="funnel-item" id="funnel-item-{{ funnel._id }}">
          <app-funnel-card
            [funnelItem]="funnel"
            (funnelExported)="loadFunnel()"
          />
        </div>
      }
    </div>
  }
</main>
