import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { map, Observable} from 'rxjs';
import { FilterModel, FunnelItemModel, FunnelModel } from '../../models/funnel-model';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { FilterMapping, MappingType } from '../../models/filter-mapping-model';
import { ExportFunnelRequestModel } from '../../models/funnel/export-funnel-request-model';
import { FunnelHistoryRequestModel, HistoryFunnelExportRequestModel } from '../../models/funnel/export-history-funnel-model';
import { ExportFunnelResponseModel } from '../../models/funnel/export-funnel-response-model';
import { Industry } from '../../models/industry-model';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class FunnelService {
  private fieldsMap: FilterMapping[] = [
    { source: 'industry1', dest: 'h_Industry1', type: MappingType.MULTIVALUED },
    { source: 'industry2', dest: 'h_Industry2', type: MappingType.MULTIVALUED },
    { source: 'industry3', dest: 'h_Industry3', type: MappingType.MULTIVALUED },
    { source: 'number_of_employees', dest: 'h_NoOfEmployees', type: MappingType.RANGE },
    { source: 'gross_results', dest: 'h_fin_1PYGrossProfit', type: MappingType.RANGE },
    { source: 'is_ecommerce', dest: 'h_IsEcommerce', type: MappingType.BOOL },
    { source: 'has_cms', dest: 'h_HasCmsPlatform', type: MappingType.BOOL },
    { source: 'has_analytics', dest: 'h_HasAnalyticsPlatform', type: MappingType.BOOL },
    { source: 'has_ecommerce', dest: 'h_HasEcommercePlatform', type: MappingType.BOOL },
    { source: 'has_advertising', dest: 'h_HasAdvertisingPlatform', type: MappingType.BOOL },
    { source: 'has_mkt_automation', dest: 'h_HasMktAutomationPlatform', type: MappingType.BOOL },
    { source: 'has_website', dest: 'h_Website', type: MappingType.BOOL_EXISTS },
    { source: 'umbraco', dest: 'h_IsUmbraco', type: MappingType.BOOL },
    { source: 'shopify', dest: 'h_IsShopify', type: MappingType.BOOL },
    { source: 'magento', dest: 'h_IsMagento', type: MappingType.BOOL },
    { source: 'woocommerce', dest: 'h_IsWooCommerce', type: MappingType.BOOL },
    { source: 'prestashop', dest: 'h_IsPrestaShop', type: MappingType.BOOL },
    { source: 'has_wordpress', dest: 'h_HasWordpress', type: MappingType.BOOL },
    { source: 'ecommerceCms', dest: '', type: MappingType.CHECKLIST },
    { source: 'amazon_ads', dest: 'h_IsAmazonAds', type: MappingType.BOOL },
    { source: 'google_ads', dest: 'h_IsGoogleAds', type: MappingType.BOOL },
    { source: 'facebook_ads', dest: 'h_IsFacebookAds', type: MappingType.BOOL },
    { source: 'twitter_ads', dest: 'h_IsTwitterAds', type: MappingType.BOOL },
    { source: 'linkedin_ads', dest: 'h_IsLinkedinAds', type: MappingType.BOOL },
    { source: 'ms_ads', dest: 'h_IsMicrosoftAds', type: MappingType.BOOL },
    { source: 'ads', dest: '', type: MappingType.CHECKLIST },
    { source: 'social_media', dest: '', type: MappingType.CHECKLIST },
    { source: 'crm_system', dest: '', type: MappingType.CHECKLIST },
    { source: 'company_type', dest: 'h_CompanyType', type: MappingType.MULTIVALUED },
    { source: 'email_system', dest: '', type: MappingType.CHECKLIST },
    { source: 'has_marketing_department', dest: 'h_HasMarketingDepartment', type: MappingType.BOOL },
    { source: 'has_analytics_platform', dest: 'h_HasAnalyticsPlatform', type: MappingType.BOOL },
    { source: 'facebook_url', dest: 'h_some_FacebookURL', type: MappingType.BOOL },
    { source: 'instagram_url', dest: 'h_InstagramURL', type: MappingType.BOOL },
    { source: 'tiktok_url', dest: 'h_TiktokURL', type: MappingType.BOOL },
    { source: 'linkedin_url', dest: 'h_LinkedinURL', type: MappingType.BOOL },
    { source: 'has_hubspot', dest: 'h_HasHubspot', type: MappingType.BOOL },
    { source: 'has_salesforce', dest: 'h_HasSalesforce', type: MappingType.BOOL },
    { source: 'has_pipedrive', dest: 'h_HasPipedrive', type: MappingType.BOOL },
    { source: 'has_mailchimp', dest: 'h_HasMailchimp', type: MappingType.BOOL },
    { source: 'has_active_campaign', dest: 'h_HasActiveCampaign', type: MappingType.BOOL },
    { source: 'incorporation_date', dest: 'h_DateOfIncorporation', type: MappingType.RANGE },
    { source: 'no_unique_visitors_avg_3mo', dest: 'h_NoUniqueVisitorsAvg3mo', type: MappingType.RANGE },
    { source: 'sub_industry', dest: 'h_Industry2', type: MappingType.NESTEDVALUED },
    { source: 'number_of_products', dest: 'h_ProductCount', type: MappingType.RANGE },
    { source: 'region', dest: 'h_Region', type: MappingType.MULTIVALUED },
    { source: 'province', dest: 'h_Province', type: MappingType.MULTIVALUED }
  ];
  private rawCredits = toSignal(this.getAvailableCredits(), {initialValue: -1})
  private credits = computed(() => signal(this.rawCredits()))
  creditsForDisplay = computed(() => this.credits()())
  constructor(private http: HttpClient, private authService: AuthService) { }

  getFunnel(id: any): Observable<FunnelModel> {
    let endpointUrl = environment.HINTLY_BE_URL + `/api/funnels/${this.authService.getCustomerName()}/${id}`;
    return this.http.get<{ data: FunnelModel[] }>(endpointUrl).pipe(
      map((response) => {
        if (response.data)
          return response.data[0];
        else
          return response.data;
      }));;
  }

  favoriteFunnel(id: string, isFavorite: boolean): Observable<FunnelModel> {
    let endpointUrl = environment.HINTLY_BE_URL + `/api/funnels/${this.authService.getCustomerName()}/${id}`
    return this.http.put<{data: FunnelModel}>(endpointUrl, {
      is_favorite: isFavorite
    }).pipe(
      map((res) => res.data)
    )
  }

  getFunnelList(): Observable<FunnelModel[]> {
    let endpointUrl = environment.HINTLY_BE_URL + '/api/funnels/' + this.authService.getCustomerName();
    return this.http.get<{ data: FunnelModel[] }>(endpointUrl).pipe(
      map((response) => {
        return response.data?.sort((a, b) => a.created_at! > b.created_at! ? -1 : 1);
      }));
  }

  getAllIndustries(): Observable<Industry[]> {
    let endpointUrl = environment.HINTLY_BE_URL + '/api/core/industries';
    return this.http.get<Industry[]>(endpointUrl);
  }

  create(request: FunnelModel): Observable<FunnelModel> {
    const payload = JSON.parse(JSON.stringify(request, (key, value) => (key === 'queries' ? undefined : value)));
    let endpointUrl = environment.HINTLY_BE_URL + '/api/funnels/' + this.authService.getCustomerName();
    return this.http.post<{ data: FunnelModel }>(endpointUrl, payload).pipe(
      map((response) => {
        return response.data;
      }));
  }

  countFunnelMatches(criterias: FunnelItemModel[]): any {
    const filterObj = this.getFilterForCriterias(criterias);
    let endpointUrl = environment.HINTLY_MASTER_URL + '/api/count';
    return { response: this.http.post<number>(endpointUrl, { filter: filterObj }), query: filterObj };
  }

  exportFunnel(request: ExportFunnelRequestModel): Observable<ExportFunnelResponseModel> {
    const endpointUrl = environment.HINTLY_BE_URL + '/api/crm/export-leads';
    request.customer_name = this.authService.getCustomerName() || '';
    return this.http.post<{ data: ExportFunnelResponseModel }>(endpointUrl, request).pipe(
      map((response) => {
        return response.data;
      }));
  }

  getFunnelHistoryExport(request: HistoryFunnelExportRequestModel): Observable<any> {
    const endpointUrl = environment.HINTLY_BE_URL + '/api/crm/deliveries/list';
    request.customer_name = this.authService.getCustomerName() || '';
    let params = `?funnel_id=${request.funnel_id}&customer_name=${request.customer_name}`;
    return this.http.get(endpointUrl+params);
  }

  getAvailableCredits(){
    const endpointUrl = environment.HINTLY_BE_URL + '/api/crm/get-tokens'
    return this.http.get<{available: number}>(endpointUrl).pipe(map(credit => credit.available))
  }

  updateCredits(){
    this.getAvailableCredits().subscribe({
      next: val => {
        this.credits().update(() => val)
      }
    })
  }

  getFilterForCriterias(criterias: FunnelItemModel[]): any {
    const criteriasList: any[] = [];
    const stardardFilters = [
      "industry1",
      "industry2",
      "industry3",
      "number_of_employees",
      "gross_results",
      "incorporation_date",
      "company_type",
      "region",
      "province",
    ];
    for (const criteria of criterias ?? []) {
      if (criteria.filters.length == 1) {
        const translated = this.translateToHintlyFilters(criteria.filters[0]);
        if (translated) {
          if (translated.values[0]["$or"]?.length > 0 || translated.values[0]["$and"]?.length > 0) {
            criteriasList.push(translated.values[0]);
          } else if (translated.name.length > 0) {
            criteriasList.push({ [translated.name]: translated.values[0] });
          } else {
            criteriasList.push(translated.values[0]);
          }
        }
      } else if (criteria.filters.length > 1) {
        const filtersList: any[] = [];
        for (const filter of criteria.filters) {
          const translated = this.translateToHintlyFilters(filter);
          let referenceOrFilterList = filtersList[0];
          let referenceAndFilterList = filtersList[0];
          if (translated) {
            if (translated.values[0]?.["$or"]?.length > 0) {
              if (filtersList.length == 1) {
                const orValues = filtersList[0]['$or'] ?? [];
                filtersList[0]['$or'] = [...orValues, ...translated.values[0]["$or"]];
                referenceOrFilterList = filtersList[0];
              } else {
                filtersList.push({ '$or': translated.values[0]["$or"] });
                referenceOrFilterList = filtersList[filtersList.length - 1];
              }
            }

            if (translated.values[0]?.["$and"]?.length > 0) {
              if (filtersList.length == 1) {
                const andValues = filtersList[0]['$and'] ?? [];
                filtersList[0]['$and'] = [...andValues, ...translated.values[0]["$and"]];
                referenceAndFilterList = filtersList[0];
              } else {
                filtersList.push({ '$and': translated.values[0]["$and"] });
                referenceAndFilterList = filtersList[filtersList.length - 1];
              }
            }

            if (!(referenceOrFilterList?.['$or']?.length > 0 || referenceAndFilterList?.['$and']?.length > 0)) {
              if (translated.name.length > 0)
                filtersList.push({ [translated.name]: translated.values[0] });
              else
                filtersList.push(translated.values[0]);
            }

            if (filtersList.length > 0) {
              if (stardardFilters.includes(filter.name)) {
                if (criteriasList.length == 0) {
                  criteriasList.push({ '$and': filtersList });
                } else {
                  criteriasList[0] = { '$and': filtersList };
                }
              } else {
                criteriasList.push({ '$and': filtersList });
              }
            }
          }
        }
      }
    }

    this.pushStaticFilters(criteriasList);
    const filterObj = { '$and': criteriasList };
    console.log(JSON.stringify(filterObj));
    return filterObj;
  }

  private translateToHintlyFilters(filter: FilterModel): FilterModel | null {
    const matchedMapping = this.getMapping(filter.name);
    const positiveQuery: any[] = [];
    const negatedQuery: any[] = [];

    if (filter.queries && !filter.values)
      filter.values = filter.queries;
    if (!matchedMapping) {
      return null;
    }
    let destination = matchedMapping.dest;
    let generatedFilter: { [key: string]: any } | null = {};
    switch (matchedMapping.type) {
      case MappingType.MULTIVALUED:
        for (let value of filter.values) {
          if(filter.name.toLowerCase().includes('industry')){
            //remove the prefix from the label
            value = value.split('-');
            if(value.length > 1) value = value[1].trim();
            else value = value[0];
          }
          if (value.inverse) {
            negatedQuery.push(value.name ? value.name : value);
          } else {
            positiveQuery.push(value.name ? value.name : value);
          }
        }

        if (negatedQuery?.length > 0) {
          generatedFilter["$and"] = [{ [destination]: { '$nin': negatedQuery } }];
          if (positiveQuery?.length > 0) {
            generatedFilter["$or"] = [{ [destination]: { '$in': positiveQuery } }];
          }
        } else if(positiveQuery.length > 0){
          generatedFilter = { '$in': positiveQuery };
        }
        break;
      case MappingType.NESTEDVALUED:
        for (const value of filter.values) {
          if (value.inverse) {
            negatedQuery.push(value.value);
          } else {
            positiveQuery.push(value.value);
          }
          destination = value.name;
        }

        if (positiveQuery?.length > 0) {
          generatedFilter["$or"] = [{ [destination]: { '$in': positiveQuery } }];
        }

        if (negatedQuery?.length > 0) {
          generatedFilter["$and"] = [{ [destination]: { '$nin': negatedQuery } }];
        }

        if (negatedQuery?.length == 0) {
          delete generatedFilter['$and'];
        }

        if (positiveQuery?.length == 0) {
          delete generatedFilter['$or'];
        }

        if (positiveQuery.length == 0 && negatedQuery.length == 0) {
          generatedFilter = {};
        }
        break;
      case MappingType.RANGE:
        if (filter.values[0] != '' && filter.values[1] != '') {
          generatedFilter = { 
            '$gte': filter.name == 'incorporation_date' ? filter.values[0] : parseInt(filter.values[0]), 
            '$lte': filter.name == 'incorporation_date' ? filter.values[1] : parseInt(filter.values[1]) 
          };
        } else if (filter.values[0] != '' || filter.values[1] != '') {
          if (filter.values[1] != '') {
            generatedFilter = { '$lte': filter.name == 'incorporation_date' ? filter.values[1] : parseInt(filter.values[1]) };
          } else if (filter.values[0] != '') {
            generatedFilter = { '$gte': filter.name == 'incorporation_date' ? filter.values[0] : parseInt(filter.values[0]) };
          }
        }
        break;
      case MappingType.BOOL:
        if (filter.values.length > 0) {
          filter.values.forEach((value: any, index: number) => {
            generatedFilter = filter.queries[index]?.value;
          });
        }
        break;
      case MappingType.BOOL_EXISTS:
        generatedFilter = filter.queries[0]?.value;
        break;

      case MappingType.CHECKLIST:
        filter.queries.forEach((query: any, index: number) => {
          const mapped = this.getMapping(query.name);
          if (mapped) {
            if (filter.values[index].inverse) {
              negatedQuery.push({ [mapped.dest]: query.value });
            } else {
              positiveQuery.push({ [mapped.dest]: query.value });
            }
          }
        });

        if (positiveQuery.length > 0)
          generatedFilter["$or"] = positiveQuery;

        if (negatedQuery.length > 0)
          generatedFilter['$and'] = negatedQuery;

        if (positiveQuery.length == 0 && generatedFilter["$or"]) {
          delete generatedFilter["$or"];
        }

        if (negatedQuery.length == 0 && generatedFilter['$and']) {
          delete generatedFilter['$and'];
        }

        if (positiveQuery.length == 0 && negatedQuery.length == 0)
          generatedFilter = {};

        break;
    }
    if (!generatedFilter || Object.keys(generatedFilter).length == 0) return null;
    const filterObj: FilterModel = { name: destination, values: [generatedFilter] };
    return filterObj;
  }

  pushStaticFilters(criteriasList: any[]): void {
    criteriasList.push({ h_CompanyStatus: { '$nin': [/inactive/i] } });
    criteriasList.push({ h_Country: 'DK' });
  }

  getMapping(key: string): FilterMapping | null {
    const matchedMapping = this.fieldsMap.filter(map => map.source == key);
    if (matchedMapping.length == 0) {
      return null;
    }
    return matchedMapping[0];
  }
}
