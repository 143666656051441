<div *ngIf="isLoggedIn()" class="header-wrapper w-100">
  <div class="header-content d-flex justify-content-between align-items-center">
    <div class="logo-wrapper">
      <a routerLink="/dashboard/">
        <img src="../../../../assets/images/icons/hintly-logo.png" alt="hintly-logo" referrerpolicy="no-referrer"/>
      </a>
    </div>
    <div class="d-flex">
      <p class="credits" *ngIf="totalCredits > 0 && usedCredits > 0">Credits usage: <span class="usage">300 / 10.000</span></p>
      <div class="image">
        <a routerLink="/funnel-management/">
          <img src="../../../../assets/images/icons/plus.png" width="24" alt="Create Funnel Icon"/>
        </a>
      </div>
      <div class="image">
        <img src="../../../../assets/images/icons/gear.png" width="24" alt="Edit Profile Icon" />
      </div>
    </div>
  </div>
</div>
