<div class="funnel-card">
  @if(isFunnelPreview){
    <app-funnel-card-header [funnelItem]="funnelItem" [name]="name" />
  }@else {
    <app-funnel-card-header [funnelItem]="funnelItem" />
  }
  <app-funnel-card-content >
    @if(isFunnelPreview){
      <app-funnel
      class="funnelItem"
      [criterias]="funnelItem.criterias"
    />
    }@else {
      <app-funnel-progress />
      <app-funnel
      class="funnelItem"
      [id]="funnelItem._id"
      [criterias]="funnelItem.criterias"
    />
      <app-funnel-metrics />
    }
  </app-funnel-card-content>
  
</div>
