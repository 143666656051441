import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';
import { FunnelService } from '../../../../services/funnel/funnel.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { SpinnerService } from '../../../../services/core/spinner/spinner.service';
import { FunnelCardComponent } from '../../../custom/funnel-card/funnel-card.component';
import { FunnelModel } from '../../../../models/funnel-model';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    CommonModule,
    FunnelCardComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  providers: [AuthService, FunnelService],
})
export class DashboardComponent implements OnInit {
  funnels: FunnelModel[] = [];

  private spinnerService: SpinnerService = inject(SpinnerService);
  private funnelService: FunnelService = inject(FunnelService);

  ngOnInit(): void {
    this.loadFunnel();
  }

  loadFunnel(): void {
    this.spinnerService.show();
    this.funnelService.getFunnelList().subscribe((data) => {
      this.funnels = data.sort((funnelA, funnelB) =>
        funnelA.is_favorite === funnelB.is_favorite ? 0 : funnelA.is_favorite ? -1 : 1,
      );
      this.spinnerService.hide();
    });
  }
}
