import { Component, computed, Signal } from '@angular/core';
import { AuthStatusService } from '../../../services/auth/auth-status.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  isLoggedIn:Signal<boolean> = computed(() => this.authStatusService.isLoggedIn());
  constructor(private authStatusService: AuthStatusService) {}
}
