<div
  class="level-indicator"
  (click)="handleUpdate()"
  id="level-indicator-{{ filterName }}"
  (mouseenter)="showCloseButton(true)"
  (mouseleave)="showCloseButton(false)"
>
  <div [ngClass]="closeButton ? 'close-button' : 'text'">
    {{ closeButton ? '&times;' : computedPositions()[filterName] }}
  </div>
</div>
