<main>
  <div class="funnels-container">
    <div *ngFor="let item of funnels;"
      class="funnel-item"
      id="funnel-item-{{item._id}}"
    >
      <app-funnel-card [funnelItem]="item"></app-funnel-card>
    </div>
  </div>
</main>
