import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import authenticateUserByPassword from '../../../assets/mocks/fake-authenticate-user-by-password';
import { AuthStatusService } from './auth-status.service';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly authUrl = 'assets/mocks/fake-auth-response.json';
  private user: any = null;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private authStatusService: AuthStatusService) {
    this.loadUserFromStorage();
  }

  get loggedIn(): boolean {
    return this.cookieService.get(environment.TOKEN_KEY) != '';
  }

  login(username: string, password: string): Observable<any> {
    let authUrl = `${environment.HINTLY_API_URL}/realms/HINTLY/protocol/openid-connect/token`;

    const body = new URLSearchParams();
    body.set('grant_type', 'password');
    body.set('username', username);
    body.set('password', password);
    body.set('client_id', 'hintly-fe');
    body.set('scope', 'offline_access'); 

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<any>(authUrl, body.toString(), { headers }).pipe(
      map((response) => {
        if (username && password) {
          this.storeUserData(response);
          return response;
        } else {
          throw new Error('Invalid credentials');
        }
      })
    );
  }

  refreshToken(): Observable<string> {
    let authUrl = `${environment.HINTLY_API_URL}/realms/HINTLY/protocol/openid-connect/token`;
    const refreshToken = localStorage.getItem('refresh_token');
    if(!refreshToken){
      throw new Error('Refresh token not found');
    }
    const body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refreshToken);
    body.set('client_id', 'hintly-fe');
    body.set('scope', 'offline_access');
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.http.post<any>(authUrl, body.toString(), { headers }).pipe(
      map((response) => {
          this.storeUserData(response);
          return response.access_token;
      })
    );
  }

  logout(): void {
    localStorage.removeItem(environment.TOKEN_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
    localStorage.removeItem(environment.USER_KEY);
    this.cookieService.set(environment.TOKEN_KEY, "");
    this.user = null;
    this.authStatusService.setLoggedIn(false);
  }

  private loadUserFromStorage(): void {
    const token = localStorage.getItem(environment.TOKEN_KEY);
    const user = localStorage.getItem(environment.USER_KEY);
    if (token) {
      if (user)
        this.user = JSON.parse(user);
      this.authStatusService.setLoggedIn(true);
    }
  }

  private storeUserData(response: any): void {
    this.setToken(response.access_token);
    localStorage.setItem(environment.REFRESH_TOKEN_KEY, response.refresh_token);
    if (response.user)
      localStorage.setItem(environment.USER_KEY, JSON.stringify(response.user));
    this.cookieService.set(environment.REFRESH_TOKEN_KEY, response.refresh_token);
    this.authStatusService.setLoggedIn(true);
    this.user = response.user;
  }

  getUser(): any {
    return this.user;
  }

  getAccessToken(): string | null {
    return localStorage.getItem(environment.TOKEN_KEY);
  }

  getCustomerName(): string | null {
    const token = this.getAccessToken();
    if (token) {
      const tokenObject = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
      return tokenObject.customer_id;
    }
    return null;
  }

  setToken(token: string) {
    localStorage.setItem(environment.TOKEN_KEY, token);
    this.cookieService.set(environment.TOKEN_KEY, token);
  }
}
