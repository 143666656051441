<div class="export-container">
  <div class="header">
    <div class="close">
      <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div class="title">Export CSV</div>
    <div class="subtitle">Choose your settings before exporting</div>
  </div>
  <div class="body">
    <div class="settings-container">
      <div class="max-sample-size">
        <p><label>Number of Leads to be Exported</label></p>
        <app-regular-slider
          [disabled]="false"
          [min]="1000"
          [max]="5000"
          [step]="500"
          (valueChange)="setSampleSize($event)"
        >
        </app-regular-slider>
      </div>
    </div>
    <div class="button-container">
      <button (click)="close()" class="btn btn-secondary mb-6">
        Cancel
      </button>
      <button (click)="export()" class="btn btn-primary mb-6">
        <img src="{{ imgPath }}download.png" alt="export icon">
        Export
      </button>
    </div>
  </div>
</div>
