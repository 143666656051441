import { CommonModule, registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { RangeSliderEvent } from '../../../models/range-slider-event';
registerLocaleData(localeDa);

@Component({
  selector: 'app-range-slider',
  standalone: true,
  imports: [CommonModule, MatSliderModule],
  providers: [{ provide: LOCALE_ID, useValue: 'da' }],
  templateUrl: './range-slider.component.html',
  styleUrl: './range-slider.component.scss',
})
export class RangeSliderComponent implements OnInit {
  @Input() min!: number;
  @Input() max!: number;
  @Input() pattern!: string;
  @Input() isDate?: boolean;
  @Input() savedValues!: any;
  @Input() disabled: boolean = false;
  @Input() step: number = 50;
  @ViewChild('start') start!: ElementRef;
  @ViewChild('end') end!: ElementRef;

  minValue!: number;
  maxValue!: number;

  @Output() valueChange = new EventEmitter<RangeSliderEvent>();

  ngOnInit(): void {
    this.formatLabel = this.formatLabel.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['savedValues'] && this.savedValues && this.disabled) {
      this.minValue = this.savedValues[0] ?? this.min;
      this.maxValue = this.savedValues[1] ?? this.max;
    } else {
      this.minValue = this.min;
      this.maxValue = this.max;
    }
  }

  onInputChange() {
    this.minValue = +this.start?.nativeElement?.value;
    this.maxValue = +this.end?.nativeElement?.value;

    this.valueChange.emit({
      min: this.isDate
        ? this.returnISOFormatedDate(this.minValue.toString())
        : this.minValue?.toString(),
      max: this.isDate
        ? this.returnISOFormatedDate(this.maxValue.toString())
        : this.maxValue.toString(),
      minLimit: this.isDate
        ? this.returnISOFormatedDate(this.min.toString())
        : this.min.toString(),
      maxLimit: this.isDate
        ? this.returnISOFormatedDate(this.max.toString())
        : this.max.toString(),
    });
  }

  returnISOFormatedDate(value: string) {
    return new Date(value).toISOString();
  }

  formatLabel(value: number): string {
    if (this.isDate) return value + (this.pattern ? this.pattern : '');
    return (
      new Intl.NumberFormat('da-DK').format(value) +
      `${this.pattern ? this.pattern : ''}`
    );
  }
}
