<div class="funnel-base">
  <div class="funnel-box">
    @for (item of funnelItems; track item.position) {
      <div class="funnel-row row-{{item.position}}">
        <div class="number">{{ item.counter }}</div>
        <!-- if item position is 0 and has no description set description to be No filter -->
        <div class="name">{{ item.position === 1 && !item.description ? 'No filter' : item.description }}</div>
      </div>
    }
  </div>
</div>
