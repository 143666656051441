import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ItemModel } from '../../models/custom/tree-view-model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  private readonly industryListUrl = 'assets/data/get-industries-list.json';
  private readonly platformListUrl = 'assets/data/get-platforms-list.json';
  private readonly adListUrl = 'assets/data/get-ads-list.json';
  private readonly socialMediaUrl = 'assets/data/get-social-media-list.json';
  private readonly emailSystemUrl = 'assets/data/get-email-system-list.json';
  private readonly crmSystemUrl = 'assets/data/get-crm-system-list.json';
  private readonly ecommerceCmsUrl = 'assets/data/get-ecommerce-cms-list.json';

  constructor(private http: HttpClient) { }

  getIndustryList(): Observable<ItemModel[]> {
    return this.http.get<ItemModel[]>(this.industryListUrl).pipe(
      map((response) => {
        response = this.filterIndustryItems(response);
        return response.sort((a, b) => a.label.localeCompare(b.label));
      })
    );
  }

  getStandardIndustryList(): Observable<ItemModel[]> {
    return this.http.get<ItemModel[]>(this.industryListUrl).pipe(
      map((response) => {
        response = this.filterStandardIndustryItems(response);
        return response.sort((a, b) => a.label.localeCompare(b.label));
      })
    );
  }

  getPlatformList(): Observable<ItemModel[]> {
    return this.http.get<ItemModel[]>(this.platformListUrl).pipe(
      map((response) => {
        return response.sort((a, b) => a.label.localeCompare(b.label));
      }));
  }

  getAdsList(): Observable<ItemModel[]> {
    return this.http.get<ItemModel[]>(this.adListUrl).pipe(
      map((response) => {
        return response.sort((a, b) => a.label.localeCompare(b.label));
      }));
  }

  getSocialMediaList(): Observable<ItemModel[]> {
    return this.http.get<ItemModel[]>(this.socialMediaUrl).pipe(
      map((response) => {
        return response;
      }));
  }
  
  getEmailSystemList(): Observable<ItemModel[]> {
    return this.http.get<ItemModel[]>(this.emailSystemUrl).pipe(
      map((response) => {
        return response;
      }));
  }

  getCrmSystemList(): Observable<ItemModel[]> {
    return this.http.get<ItemModel[]>(this.crmSystemUrl).pipe(
      map((response) => {
        return response;
      }));
  }

  getEcommerceCmsList(): Observable<ItemModel[]> {
    return this.http.get<ItemModel[]>(this.ecommerceCmsUrl).pipe(
      map((response) => {
        return response;
      }));
  }

  getCompanyTypeList(): Observable<ItemModel[]> {
    let endpointUrl = environment.HINTLY_MASTER_URL + '/api/company-types';
    return this.http.get<ItemModel[]>(endpointUrl).pipe(
      map((response) => {
        return response;
      }));
  }

   private filterIndustryItems(items: ItemModel[]): any {
    return items
    .filter(item => item.name !== 'h_industry4')
    .map(item => {
      const filteredSubItems = item.subItems ? this.filterIndustryItems(item.subItems) : null;
      return {
        ...item,
        subItems: filteredSubItems && filteredSubItems.length > 0 ? filteredSubItems : null,
      };
    });
  }

  private filterStandardIndustryItems(items: ItemModel[]): any {
    return items
    .filter(item => item.name === 'h_industry1')
    .map(item => {
      const filteredSubItems = item.subItems ? this.filterStandardIndustryItems(item.subItems) : null;
      return {
        ...item,
        subItems: filteredSubItems && filteredSubItems.length > 0 ? filteredSubItems : null,
      };
    });
  }
}