<div class="funnel-metrics d-flex d-none">
    <div class="circle-chart">
      <svg viewBox="0 0 36 36" class="circular-chart">
        <path
          class="circle-bg"
          d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="circle"
          [attr.stroke-dasharray]="conversionRate + ',100'"
          d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
      <div class="circle-label">
        <div class="percentage">{{ conversionRate }}%</div>
        <div class="subtext">Meeting booked <br />Conversion rate</div>
      </div>
    </div>
    <div class="circle-chart">
      <svg viewBox="0 0 36 36" class="circular-chart">
        <path
          class="circle-bg"
          d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="circle lost"
          [attr.stroke-dasharray]="lostConversionRate + ',100'"
          d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
      <div class="circle-label">
        <div class="percentage lost">{{ lostConversionRate }}%</div>
        <div class="subtext">Won/Lost <br />Conversion rate</div>
      </div>
    </div>
  </div>