import { Component } from '@angular/core';

@Component({
  selector: 'app-funnel-card-content',
  standalone: true,
  imports: [],
  templateUrl: './funnel-card-content.component.html',
  styleUrl: './funnel-card-content.component.scss',
})
export class FunnelCardContentComponent {}
