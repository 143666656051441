import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [CommonModule, MatSliderModule],
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss'
})
export class SliderComponent {
  @Input() min!: number;
  @Input() max!: number;
  @Input() pattern!: string;
  @ViewChild('start') start!: ElementRef;
  @ViewChild('end') end!: ElementRef;

  @Output() valueChange = new EventEmitter<{ min: string; max: string }>();

  onInputChange(event: any) {
    this.valueChange.emit({ min: this.start.nativeElement.value, max: this.end.nativeElement.value });
  }
}
