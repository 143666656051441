import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemModel } from '../../../models/custom/tree-view-model';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { CHECKBOX_STATES } from '../../../models/constants/checkbox-states';

@Component({
  selector: 'app-tri-state-checkbox',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, FormsModule, ReactiveFormsModule],
  templateUrl: './tri-state-checkbox.component.html',
  styleUrl: './tri-state-checkbox.component.scss'
})
export class TriStateCheckboxComponent {
  @Input() items!: ItemModel[];
  @Input() selectedItems: { [key: string]: number } = {}; // State per item: 0 = unchecked, 1 = checked, 2 = opposite
  @Input() disabled: boolean = false;
  @Input() savedValues!: ItemModel[];
  @Input() showLabel: boolean = false;
  @Output() valueChange = new EventEmitter<ItemModel[]>();

  public states:any = CHECKBOX_STATES;

  ngOnInit(): void {

    if (this.savedValues) {
      this.savedValues.forEach(item => {
        if (!item.inverse)
          this.selectedItems[item.value] = CHECKBOX_STATES.CHECKED;
        else
          this.selectedItems[item.value] = CHECKBOX_STATES.OPPOSITE;
      });
    }

    this.items.forEach(item => {
      if (!(item.value in this.selectedItems)) {
        this.selectedItems[item.value] = 0;
      }
    });
  }

  onItemChange(itemValue: string, checkboxNo: MatCheckbox, checkboxYes: MatCheckbox, inverseCheckBox: boolean = false): void {
    let nextState = CHECKBOX_STATES.UNCHECKED;
    if (inverseCheckBox) {
      if (this.selectedItems[itemValue] === CHECKBOX_STATES.OPPOSITE) {
        this.selectedItems[itemValue] = CHECKBOX_STATES.UNCHECKED;
        nextState = CHECKBOX_STATES.UNCHECKED;
      } else {
        nextState = CHECKBOX_STATES.OPPOSITE;
        checkboxNo.checked = true;
        checkboxYes.checked = false;
      }
    } else {
      if (this.selectedItems[itemValue] === CHECKBOX_STATES.CHECKED) {
        this.selectedItems[itemValue] = CHECKBOX_STATES.UNCHECKED;
        nextState = CHECKBOX_STATES.UNCHECKED;
      } else {
        nextState = CHECKBOX_STATES.CHECKED;
        checkboxNo.checked = false;
        checkboxYes.checked = true;
      }
    }



    if (nextState === CHECKBOX_STATES.UNCHECKED) {
      checkboxYes.checked = false;
      checkboxNo.checked = false;
    }

    this.selectedItems[itemValue] = nextState;

    this.valueChange.emit(this.getSelectedValues());
  }

  getSelectedValues(): ItemModel[] {
    let items = this.items.filter(item => this.selectedItems[item.value]).map(item => ({
      name: item.name,
      value: item.value,
      label: item.label,
      inverse: (this.selectedItems[item.value] === CHECKBOX_STATES.OPPOSITE) ? true : false
    }));
    return items;
  }

}
