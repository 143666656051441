<mat-slider
  class="hintly-slider"
  discrete
  (change)="onInputChange($event)"
  [min]="min" [max]="max"
>
  <input [value]="min" #start matSliderStartThumb>
  <input [value]="max" #end matSliderEndThumb>
</mat-slider>
<div class="range">
  <div class="min-value">{{ min }} {{ pattern }}</div>
  <div class="max-value">{{ max }} {{ pattern }}</div>
</div>
