import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FunnelItemModel } from '../../../models/funnel-model';

@Component({
  selector: 'app-funnel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './funnel.component.html',
  styleUrl: './funnel.component.scss'
})
export class FunnelComponent {
  @Input() funnelItems!: FunnelItemModel[];
}
