<div class="content">
  <div class="left-side" *ngIf="!loading">
    <form [formGroup]="funnelForm">
      <div class="input-container mb-3">
        <input
          type="text"
          id="name"
          placeholder="Funnel name"
          formControlName="name"
          class="input-group mt-3 funnel-name-input"
          [ngClass]="{ 'is-invalid': funnelForm.controls['name'].touched && funnelForm.controls['name'].invalid }"
        />
        <div *ngIf="funnelForm.controls['name'].touched && funnelForm.controls['name'].invalid" class="invalid-feedback">
          Funnel name is required
        </div>
      </div>
      <div class="filter-container">
        <h2>Funnel experimentation phases</h2>
      </div>

      <div class="filter-container">
        <h2>Standard filtering</h2>
        <div class="box-component">
          <div class="box-form big">
            <p><label>Industry</label></p>
            <ng-container *ngIf="industries && industries.length > 0">
              <app-tree-view
                [items]="industries"
                [disabled]="viewMode"
                [savedValues]="funnelForm.get('industry_advanced')?.value"
                (valueChange)="setCheckboxList($event, 'industry')"
              >
            </app-tree-view>
            </ng-container>
          </div>
          <div class="box-form x-small">
            <p><label>Gross results</label></p>
            <app-range-slider
              class="slider"
              [savedValues]="funnelForm.get('gross_results')?.value"
              [step]="1000"
              [min]="-100000"
              [max]="100000"
              [disabled]="viewMode"
              (valueChange)="sliderValueChange($event, 'gross_results')"
            >
            </app-range-slider>
          </div>
          <div class="box-form x-small">
            <p><label>Number of employees</label></p>
            <app-range-slider
              class="slider"
              [savedValues]="funnelForm.get('number_of_employees')?.value"
              [min]="0"
              [max]="5000"
              [disabled]="viewMode"
              (valueChange)="sliderValueChange($event, 'number_of_employees')"
            >
            </app-range-slider>
          </div>
        </div>
      </div>

      <div class="filter-container">
        <h2>Hintly advanced</h2>
        <div class="box-component">
          <div class="box-form select-size">
            <div class="multi-box-select">
              <div class="label" [ngClass]="{'disabled': viewMode}">Is e-commerce</div>
              <div class="checkbox">
                <ng-container *ngIf="isEcommerceList && isEcommerceList.length > 0">
                  <app-tri-state-checkbox
                  [disabled]="viewMode"
                  [items]="isEcommerceList"
                  [savedValues]="funnelForm.get('is_ecommerce')?.value"
                  (valueChange)="setCheckboxList($event, 'is_ecommerce')"></app-tri-state-checkbox>
                </ng-container>
              </div>
            </div>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="is_ecommerce"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
              [disabled]="viewMode"
            />
          </div>
          <div class="box-form select-size">
            <div class="multi-box-select">
              <div class="label" [ngClass]="{'disabled': viewMode}">Has Marketing Department</div>
              <div class="checkbox">
                <ng-container *ngIf="hasMarketingDepartmentList && hasMarketingDepartmentList.length > 0">
                  <app-tri-state-checkbox
                  [disabled]="viewMode"
                  [items]="hasMarketingDepartmentList"
                  [savedValues]="funnelForm.get('has_marketing_department')?.value"
                  (valueChange)="setCheckboxList($event, 'has_marketing_department')"></app-tri-state-checkbox>
                </ng-container>
              </div>
            </div>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="has_marketing_department"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
              [disabled]="viewMode"
            />
          </div>
          <div class="box-form select-size">
            <div class="multi-box-select">
              <div class="label" [ngClass]="{'disabled': viewMode}">Has Analytics Platform</div>
              <div class="checkbox">
                <ng-container *ngIf="hasAnalyticsPlatformList && hasAnalyticsPlatformList.length > 0">
                  <app-tri-state-checkbox
                  [disabled]="viewMode"
                  [items]="hasAnalyticsPlatformList"
                  [savedValues]="funnelForm.get('has_analytics_platform')?.value"
                  (valueChange)="setCheckboxList($event, 'has_analytics_platform')"></app-tri-state-checkbox>
                </ng-container>
              </div>
            </div>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="has_analytics_platform"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
              [disabled]="viewMode"
            />
          </div>
          <div class="box-form select-size">
            <div class="multi-box-select">
              <div class="label" [ngClass]="{'disabled': viewMode}">Has Website</div>
              <div class="checkbox">
                <ng-container *ngIf="hasWebsiteList && hasWebsiteList.length > 0">
                  <app-tri-state-checkbox
                  [disabled]="viewMode"
                  [items]="hasWebsiteList"
                  [savedValues]="funnelForm.get('has_website')?.value"
                  (valueChange)="setCheckboxList($event, 'has_website')"></app-tri-state-checkbox>
                </ng-container>
              </div>
            </div>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="has_website"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
              [disabled]="viewMode"
            />
          </div>
        </div>

        <div class="box-component">
          <div class="box-form big">
            <p><label>Sub Industries</label></p>
            <ng-container *ngIf="subIndustries && subIndustries.length > 0">
              <app-tree-view
                [items]="subIndustries"
                [disabled]="viewMode"
                [savedValues]="funnelForm.get('sub_industry_advanced')?.value"
                [hideCheckbox]="true"
                [displayOnlySavedValues]="true"
                (valueChange)="setCheckboxList($event, 'sub_industry_advanced')"
              >
            </app-tree-view>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="sub_industry_advanced"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
              [disabled]="viewMode"
            />
            </ng-container>
          </div>
        </div>

        <div class="box-component">
          <div class="box-form medium">
            <p><label>Platforms</label></p>
            <ng-container *ngIf="platforms && platforms.length > 0">
              <app-tree-view
                [disabled]="viewMode"
                [items]="platforms"
                [savedValues]="funnelForm.get('platform')?.value"
                (valueChange)="setCheckboxList($event, 'platform')"
              ></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="platform"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
              [disabled]="viewMode"
            />
          </div>
          <div class="box-form medium">
            <p><label>Advertisement</label></p>
            <ng-container *ngIf="ads && ads.length > 0">
              <app-tree-view
                [disabled]="viewMode"
                [items]="ads"
                [savedValues]="funnelForm.get('ads')?.value"
                (valueChange)="setCheckboxList($event, 'ads')"
              ></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="ads"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
              [disabled]="viewMode"
            />
          </div>
        </div>

        <div class="box-component">
          <div class="box-form medium">
            <p><label>Social Media</label></p>
            <ng-container *ngIf="socialMedia && socialMedia.length > 0">
              <app-tree-view
                [disabled]="viewMode"
                [items]="socialMedia"
                [savedValues]="funnelForm.get('social_media')?.value"
                (valueChange)="setCheckboxList($event, 'social_media')"></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="social_media"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
              [disabled]="viewMode"
            />
          </div>
          <div class="box-form medium">
            <p><label>Ecommerce CMS</label></p>
            <ng-container *ngIf="ecommerceCmsList && ecommerceCmsList.length > 0">
              <app-tree-view
                [disabled]="viewMode"
                [items]="ecommerceCmsList"
                [savedValues]="funnelForm.get('ecommerceCms')?.value"
                (valueChange)="setCheckboxList($event, 'ecommerceCms')"></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="ecommerceCms"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
              [disabled]="viewMode"
            />
          </div>
        </div>
        <div class="box-component">
          <div class="box-form medium">
            <p><label>E-mail System</label></p>
            <ng-container *ngIf="emailSystemList && emailSystemList.length > 0">
              <app-tree-view
                [disabled]="viewMode"
                [items]="emailSystemList"
                [savedValues]="funnelForm.get('email_system')?.value"
                (valueChange)="setCheckboxList($event, 'email_system')"></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="email_system"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
              [disabled]="viewMode"
            />
          </div>
          <div class="column-container medium">
            <div class="box-form x-small no-bottom-margin">
              <p><label>Incorporation Date</label></p>
              <app-range-slider
                class="slider"
                [step]="1"
                [min]="1950"
                [max]="lastYear"
                [disabled]="viewMode"
                [savedValues]="funnelForm.get('incorporation_date')?.value"
                (valueChange)="sliderValueChange($event, 'incorporation_date', true)"
              >
              </app-range-slider>
              <app-criteria-position
                [handleChange]="handlePositionChange"
                filterName="incorporation_date"
                [positions]="sortingValues"
                [availablePositions]="availablePositions"
                [disabled]="viewMode"
              />
            </div>
          </div>
        </div>
        <div class="box-component">
          <div class="box-form big">
            <p><label>Company type</label></p>
            <ng-container *ngIf="companyTypeList && companyTypeList.length > 0">
              <app-tree-view
                [disabled]="viewMode"
                [items]="companyTypeList"
                [savedValues]="funnelForm.get('company_type')?.value"
                (valueChange)="setCheckboxList($event, 'company_type')"></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="company_type"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
              [disabled]="viewMode"
            />
          </div>
          <div class="column-container medium">
            <div class="box-form small">
              <p><label>CRM System</label></p>
              <ng-container *ngIf="crmSystemList && crmSystemList.length > 0">
                <app-tree-view
                  [disabled]="viewMode"
                  [items]="crmSystemList"
                  [savedValues]="funnelForm.get('crm_system')?.value"
                  (valueChange)="setCheckboxList($event, 'crm_system')"></app-tree-view>
              </ng-container>
              <app-criteria-position
                [handleChange]="handlePositionChange"
                filterName="crm_system"
                [positions]="sortingValues"
                [availablePositions]="availablePositions"
                [disabled]="viewMode"
              />
            </div>
            <div class="box-form x-small no-bottom-margin">
              <p><label>Web Traffic</label></p>
              <app-range-slider
                class="slider"
                [step]="10000"
                [min]="0"
                [max]="10000000"
                [disabled]="viewMode"
                [savedValues]="funnelForm.get('no_unique_visitors_avg_3mo')?.value"
                (valueChange)="sliderValueChange($event, 'no_unique_visitors_avg_3mo', true)"
              >
              </app-range-slider>
              <app-criteria-position
                [handleChange]="handlePositionChange"
                filterName="no_unique_visitors_avg_3mo"
                [positions]="sortingValues"
                [availablePositions]="availablePositions"
                [disabled]="viewMode"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="right-side">
    <div class="funnel-name">
      {{ funnelForm.controls['name'].value }}
    </div>
    <ng-container *ngIf="funnel">
      <app-funnel [funnelItems]="funnel.criterias" class="funnel-item"></app-funnel>
    </ng-container>
    <div class="button-container mt-3" *ngIf="!viewMode">
      <button (click)="save()" class="btn btn-primary mb-3">
        Save funnel
      </button>
    </div>
  </div>
</div>
