import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-funnel-metrics',
  standalone: true,
  imports: [],
  templateUrl: './funnel-metrics.component.html',
  styleUrl: './funnel-metrics.component.scss',
})
export class FunnelMetricsComponent implements OnInit {
  @Input() funnelConversionRate? = 0;
  @Input() funnelLostConversionRate? = 0;
  conversionRate = '';
  lostConversionRate = '';

  ngOnInit(): void {
    this.conversionRate = this.funnelConversionRate
      ? (this.funnelConversionRate * 100).toFixed(0)
      : '0';
    this.lostConversionRate = this.funnelLostConversionRate
      ? (this.funnelLostConversionRate * 100).toFixed(0)
      : '0';
  }
}
