import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FunnelService } from '../../../services/funnel/funnel.service';
import { ExportFunnelRequestModel } from '../../../models/funnel/export-funnel-request-model';
import { SpinnerService } from '../../../services/core/spinner/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RangeSliderComponent } from "../range-slider/range-slider.component";
import { RegularSliderComponent } from "../regular-slider/regular-slider.component";


@Component({
  selector: 'app-export-funnel',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatIconModule, ReactiveFormsModule, RangeSliderComponent, RegularSliderComponent],
  templateUrl: './export-funnel.component.html',
  styleUrl: './export-funnel.component.scss'
})
export class ExportFunnelComponent {
  private snackBar = inject(MatSnackBar);
  readonly dialogRef = inject(MatDialogRef<ExportFunnelComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);
  public imgPath = './../../../../assets/images/icons/';
  sampleSize: number = 1000;

  constructor(private funnelService: FunnelService,
    private spinnerService: SpinnerService) {}

  ngOnInit(): void {
  }

  export(): void {
    this.spinnerService.show();
    let request: ExportFunnelRequestModel = {
      funnel_id:  this.data._id || '',
      customer_name: '',
      max_sample_size: this.sampleSize
    };
    this.funnelService.exportFunnel(request).subscribe({
      next: (blob: Blob) => {
        this.spinnerService.hide();

        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${this.data.name}.csv`;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);

        this.dialogRef.close();
      },
      error: (err) => {
        this.spinnerService.hide();
        console.error('Error downloading CSV:', err);
        this.snackBar.open(`Error downloading CSV: ${err.message}`, 'Error', { duration: 5000 });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  setSampleSize(event: any): void {
    const newSampleSize = parseInt(event.value);
    if (!isNaN(newSampleSize)) {
      this.sampleSize = newSampleSize;
    }

    console.log('Sample size:', this.sampleSize);
  }
}
