<div class="funnel-base">
  <div class="funnel-box">
    @for (item of funnelItems; track item.position) {
      <div class="funnel-row row-{{item.position}}">
        <div class="number">{{ item.counter }}</div>
        <div class="name">{{ item.description }}</div>
      </div>
    }
  </div>
</div>
