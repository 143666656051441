import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDividerModule, MatIconModule, RouterModule, FormsModule, ReactiveFormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  hasError: boolean = false;
  errorMessage: string = '';
  loginForm!: FormGroup;

  constructor(
    private authService: AuthService,
    private router: Router, 
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.setupForm();
    this.checkLogged();
  }

  checkLogged() {
    if (this.authService.loggedIn)
      this.router.navigate(['/dashboard']);
  }

  setupForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  
  login() {
    const { username, password } = this.loginForm.value;
    this.authService.login(username, password).subscribe({
      next: (response) => {
        this.router.navigate(['/dashboard']);
      },
      error: (error) => {
        this.hasError = true;
        this.errorMessage = "Your Email or Password are wrong";
      },
    });
  }

  logout() {
    this.authService.logout();
  }

  navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }
}
