<div class="export-container">
  <div class="header">
    <div class="close">
      <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div class="title">Export History</div>
  </div>
  <div class="body">
    <div class="settings-container">
      <ng-container *ngIf="deliveries && deliveries.length > 0">
        <table class="history">
          <tr>
            <th class="history-header">Deliveries</th>
          </tr>

          <tr *ngFor="let delivery of deliveries">
            <td class="rows">
              {{delivery.name}}.csv
              <a [href]="delivery.link" target="_blank">
                <img src="assets/images/icons/docs.svg" alt="open spreadsheet icon">
              </a>
            </td>
          </tr>
        </table>
      </ng-container>
      <ng-container *ngIf="deliveries && deliveries.length < 1">
        There is no export history available for this funnel
      </ng-container>
    </div>
    <div class="button-container">
      <button (click)="close()" class="btn btn-secondary mb-6">
        Cancel
      </button>
    </div>
  </div>
</div>
