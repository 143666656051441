import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appFunnelTier]',
  standalone: true,
})
export class AppFunnelTierDirective implements OnInit, OnChanges {
  elRef: ElementRef = inject(ElementRef);
  router: Router = inject(Router);
  @Input() funnelId?: string
  @Input() numberOfCriterias = 0;
  @HostBinding('style.background') background = '';

  ngOnChanges(changes: SimpleChanges): void {
    this.checkNumberOfCriterias(changes['numberOfCriterias'].currentValue);
  }

  ngOnInit(): void {
    this.checkNumberOfCriterias(this.numberOfCriterias);
  }

  @HostListener('click') onClick(){
    if(this.funnelId){
      this.router.navigate(['/funnel-management', this.funnelId])
    }
  } 

  checkNumberOfCriterias(criterias: number) {
    switch (criterias) {
      case 1:
        this.background = '#e6e6e6';
        break;
      case 2:
        this.background = 'linear-gradient(180deg, #e6e7e8 50%, #b5e7db 50%)';
        break;
      case 3:
        this.background =
          'linear-gradient(180deg, #e6e7e8 33%, #b5e7db 20%, #ffffff 100%)';
        break;
      case 4:
        this.background =
          'linear-gradient(180deg, #e6e7e8 25%, #b5e7db 20%, #ffffff 100%)';
        break;
      case 5:
        this.background =
          'linear-gradient(180deg, #e6e7e8 20%, #b5e7db 20%, #ffffff 100%)';
        break;
    }
  }
}
