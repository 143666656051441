<form>
  <mat-form-field class="chip-list">
    <mat-chip-grid #chipGrid>
      @for (option of options; track option) {
        <mat-chip-row (removed)="remove(option)">
          {{option}}
          <button matChipRemove [attr.aria-label]="'remove ' + option">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
      <input placeholder="" #optionInput [formControl]="optionCtrl"
             [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
             (click)="forceOptionCtrlChange()" />
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      @for (option of filteredOptions | async; track option) {
        <mat-option [value]="option">{{option}}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</form>

