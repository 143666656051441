import { Component, Input } from '@angular/core';
import { FunnelModel } from '../../../models/funnel-model';
import { FunnelComponent } from '../funnel/funnel.component';
import { FunnelCardHeaderComponent } from './funnel-card-header/funnel-card-header.component';
import { FunnelCardContentComponent } from './funnel-card-content/funnel-card-content.component';
import { FunnelProgressComponent } from '../funnel-progress/funnel-progress.component';
import { FunnelMetricsComponent } from '../funnel-metrics/funnel-metrics.component';

@Component({
  selector: 'app-funnel-card',
  standalone: true,
  imports: [
    FunnelComponent,
    FunnelCardHeaderComponent,
    FunnelCardContentComponent,
    FunnelProgressComponent,
    FunnelMetricsComponent,
  ],
  templateUrl: './funnel-card.component.html',
  styleUrl: './funnel-card.component.scss',
})
export class FunnelCardComponent {
  @Input() funnelItem!: FunnelModel;
  @Input() isFunnelPreview = false;
  @Input() name = ''
}
