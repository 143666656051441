<div class="content">
  <div class="left-side">
    <form [formGroup]="funnelForm">
      <div class="input-container mb-3">
        <input
          type="text"
          id="name"
          placeholder="Funnel name"
          formControlName="name"
          class="input-group mt-3 funnel-name-input"
          [ngClass]="{ 'is-invalid': funnelForm.controls['name'].touched && funnelForm.controls['name'].invalid }"
        />
        <div *ngIf="funnelForm.controls['name'].touched && funnelForm.controls['name'].invalid" class="invalid-feedback">
          Funnel name is required
        </div>
      </div>
      <div class="filter-container">
        <h2>Funnel experimentation phases</h2>
      </div>

      <div class="filter-container">
        <h2>Standard filtering</h2>
        <div class="box-component">
          <div class="box-form big">
            <p><label>Industry</label></p>
            <ng-container *ngIf="industries && industries.length > 0">
              <app-tree-view [items]="industries" (valueChange)="setCheckboxList($event, 'industry')"></app-tree-view>
            </ng-container>
          </div>
          <div class="box-form x-small">
            <p><label>Gross results</label></p>
            <app-slider
              class="slider"
              [min]="-100000"
              [max]="100000"
              (valueChange)="sliderValueChange($event, 'gross_results')"
            >
            </app-slider>
          </div>
          <div class="box-form x-small">
            <p><label>Number of employees</label></p>
            <app-slider
              class="slider"
              [min]="0"
              [max]="5000"
              (valueChange)="sliderValueChange($event, 'number_of_employees')"
            >
            </app-slider>
          </div>
        </div>
      </div>

      <div class="filter-container">
        <h2>Hintly advanced</h2>
        <div class="box-component">
          <div class="box-form select-size">
            <div class="multi-box-select">
              <div class="label">Is e-commerce</div>
              <div class="checkbox" (click)="updateCheckboxCriteria($event, 'is_ecommerce')">
                <mat-checkbox formControlName="is_ecommerce" class="example-margin">Yes</mat-checkbox>
              </div>
            </div>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="is_ecommerce"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
            />
          </div>
          <div class="box-form select-size">
            <div class="multi-box-select">
              <div class="label">Has Marketing Department</div>
              <div class="checkbox" (click)="updateCheckboxCriteria($event, 'has_marketing_department')">
                <mat-checkbox formControlName="has_marketing_department" class="example-margin">Yes</mat-checkbox>
              </div>
            </div>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="has_marketing_department"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
            />
          </div>
        </div>

        <div class="box-component">
          <div class="box-form medium">
            <p><label>Platforms</label></p>
            <ng-container *ngIf="platforms && platforms.length > 0">
              <app-tree-view [items]="platforms" (valueChange)="setCheckboxList($event, 'platform')"></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="platform"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
            />
          </div>
          <div class="box-form medium">
            <p><label>Advertisement</label></p>
            <ng-container *ngIf="ads && ads.length > 0">
              <app-tree-view [items]="ads" (valueChange)="setCheckboxList($event, 'ads')"></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="ads"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
            />
          </div>
        </div>

        <div class="box-component">
          <div class="box-form medium">
            <p><label>Social Media</label></p>
            <ng-container *ngIf="socialMedia && socialMedia.length > 0">
              <app-tree-view [items]="socialMedia" (valueChange)="setCheckboxList($event, 'social_media')"></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="social_media"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
            />
          </div>
          <div class="box-form medium">
            <p><label>Ecommerce CMS</label></p>
            <ng-container *ngIf="ecommerceCmsList && ecommerceCmsList.length > 0">
              <app-tree-view [items]="ecommerceCmsList" (valueChange)="setCheckboxList($event, 'ecommerceCms')"></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="ecommerceCms"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
            />
          </div>
        </div>
        <div class="box-component">
          <div class="box-form small">
            <p><label>E-mail System</label></p>
            <ng-container *ngIf="emailSystemList && emailSystemList.length > 0">
              <app-tree-view [items]="emailSystemList" (valueChange)="setCheckboxList($event, 'email_system')"></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="email_system"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
            />
          </div>
          <div class="column-container medium">
            <div class="box-form x-small no-bottom-margin">
              <p><label>Incorporation Date</label></p>
              <app-slider
                class="slider"
                [min]="1950"
                [max]="lastYear"
                (valueChange)="sliderValueChange($event, 'incorporation_date', true)"
              >
              </app-slider>
              <app-criteria-position
                [handleChange]="handlePositionChange"
                filterName="incorporation_date"
                [positions]="sortingValues"
                [availablePositions]="availablePositions"
              />
            </div>
          </div>
        </div>
        <div class="box-component">
          <div class="box-form big">
            <p><label>Company type</label></p>
            <ng-container *ngIf="companyTypeList && companyTypeList.length > 0">
              <app-tree-view [items]="companyTypeList" (valueChange)="setCheckboxList($event, 'company_type')"></app-tree-view>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="company_type"
              [positions]="sortingValues"
              [availablePositions]="availablePositions"
            />
          </div>
          <div class="column-container medium">
            <div class="box-form small">
              <p><label>CRM System</label></p>
              <ng-container *ngIf="crmSystemList && crmSystemList.length > 0">
                <app-tree-view [items]="crmSystemList" (valueChange)="setCheckboxList($event, 'crm_system')"></app-tree-view>
              </ng-container>
              <app-criteria-position
                [handleChange]="handlePositionChange"
                filterName="crm_system"
                [positions]="sortingValues"
                [availablePositions]="availablePositions"
              />
            </div>
            <div class="box-form x-small no-bottom-margin">
              <p><label>Web Traffic</label></p>
              <app-slider
                class="slider"
                [min]="0"
                [max]="10000000"
                (valueChange)="sliderValueChange($event, 'no_unique_visitors_avg_3mo', true)"
              >
              </app-slider>
              <app-criteria-position
                [handleChange]="handlePositionChange"
                filterName="no_unique_visitors_avg_3mo"
                [positions]="sortingValues"
                [availablePositions]="availablePositions"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="right-side">
    <div class="funnel-name">
      {{ funnelForm.controls['name'].value }}
    </div>
    <ng-container *ngIf="funnel">
      <app-funnel [funnelItems]="funnel.criterias" class="funnel-item"></app-funnel>
    </ng-container>
    <div class="button-container mt-3">
      <button (click)="save()" class="btn btn-primary mb-3">
        Save funnel
      </button>
    </div>
  </div>
</div>
