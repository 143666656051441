import { Component, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FunnelModel } from '../../../models/funnel-model';
import { FunnelComponent } from "../funnel/funnel.component";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-funnel-card',
  standalone: true,
  imports: [MatProgressBarModule, FunnelComponent, CommonModule],
  templateUrl: './funnel-card.component.html',
  styleUrl: './funnel-card.component.scss'
})
export class FunnelCardComponent {
  @Input() funnelItem!: FunnelModel;

  public conversionRate: string = '0';
  public lostConversionRate: string = '0';

  ngOnInit(): void {
    this.conversionRate = this.funnelItem.conversion_rate ? (this.funnelItem.conversion_rate * 100).toFixed(0) : '0';
    this.lostConversionRate = this.funnelItem.lost_conversion_rate ? (this.funnelItem.lost_conversion_rate * 100).toFixed(0) : '0';
  }

}
