<div class="content">
  <div class="left-side" *ngIf="!loading">
    <form [formGroup]="funnelForm">
      <div class="input-container my-3">
        <label for="funnelName">Funnel name</label>
        <input
          type="text"
          id="funnelName"
          formControlName="name"
          class="input-group funnel-name-input"
          [ngClass]="{
            'is-invalid':
              funnelForm.controls['name'].touched &&
              funnelForm.controls['name'].invalid,
          }"
        />
        @if (
          funnelForm.controls['name'].touched &&
          funnelForm.controls['name'].invalid
        ) {
          <label class="invalid-feedback" style="color: red">
            Funnel name is required
          </label>
        }
      </div>
      <h2>Standard filtering</h2>
      <div class="filter-container">
        <div class="box-component">
          <div class="box-form industries-form" [class.criteria-selected]="isCriteriaSelected('Industry')">
            <p>Industry</p>
            <ng-container *ngIf="industries && industries.length > 0">
              <app-tree-view
                [items]="industries"
                [parentItems]="industries"
                [filterPlaceholder]="'Filter Industries...'"
                [savedValues]="funnelForm.get('industry_advanced')?.value"
                (valueChange)="setCheckboxList($event, 'industry')"
                inderteminateDisabled="true"
                [searchable]="true"
              >
              </app-tree-view>
            </ng-container>
          </div>
          <div class="box-form countries-form" [class.criteria-selected]="isCriteriaSelected('Regions and Provinces')">
            <p><label>Regions</label></p>
            <ng-container *ngIf="countries && countries.length > 0">
              <app-tree-view
                [items]="countries"
                [parentItems]="countries"
                (valueChange)="setCheckboxList($event, 'region')"
                inderteminateDisabled="true"
              >
              </app-tree-view>
            </ng-container>
          </div>
          <div class="box-form company-type" [class.criteria-selected]="isCriteriaSelected('Company type')">
            <p>Company type</p>
            <ng-container *ngIf="companyTypeList && companyTypeList.length > 0">
              <app-tri-state-checkbox
                [showLabel]="true"
                [items]="companyTypeList"
                [savedValues]="funnelForm.get('company_type')?.value"
                (valueChange)="setCheckboxList($event, 'company_type')"
              />
            </ng-container>
          </div>
        </div>

        <div class="box-component">
          <div class="box-form gross-results" [class.criteria-selected]="isCriteriaSelected('Gross results')">
            <p>Gross results</p>
            <app-range-slider
              class="slider"
              [savedValues]="funnelForm.get('gross_results')?.value"
              [step]="1000"
              [min]="-100000"
              [max]="100000"
              [pattern]="'$'"
              (valueChange)="sliderValueChange($event, 'gross_results', false)"
            >
            </app-range-slider>
          </div>
          <div class="box-form number-of-employees" [class.criteria-selected]="isCriteriaSelected('No of Employees')">
            <p>Number of employees</p>
            <app-range-slider
              class="slider"
              [savedValues]="funnelForm.get('number_of_employees')?.value"
              [min]="0"
              [max]="5000"
              (valueChange)="
                sliderValueChange($event, 'number_of_employees', false)
              "
            >
            </app-range-slider>
          </div>
          <div class="box-form incorporation-date" [class.criteria-selected]="isCriteriaSelected('Incorporation date')">
            <p>Incorporation Date</p>
            <app-range-slider
              class="slider"
              [isDate]="true"
              [step]="1"
              [min]="1950"
              [max]="lastYear"
              [savedValues]="funnelForm.get('incorporation_date')?.value"
              (valueChange)="
                sliderValueChange($event, 'incorporation_date', false)
              "
            >
            </app-range-slider>
          </div>
        </div>
      </div>

      <h2>Hintly advanced</h2>
      <div class="filter-container">
        <div class="box-component">
          <div
            class="box-form select-size is-ecommerce"
            [class.criteria-selected]="isCriteriaSelected('is_ecommerce')"
          >
            <div class="multi-box-select">
              <div class="label" [ngClass]="{ disabled: viewMode }">
                Is e-commerce
              </div>
              <div class="checkbox">
                <ng-container
                  *ngIf="isEcommerceList && isEcommerceList.length > 0"
                >
                  <app-tri-state-checkbox
                    [items]="isEcommerceList"
                    [savedValues]="funnelForm.get('is_ecommerce')?.value"
                    (valueChange)="setCheckboxList($event, 'is_ecommerce')"
                  ></app-tri-state-checkbox>
                </ng-container>
              </div>
            </div>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="is_ecommerce"
              [positions]="positionValues"
            />
          </div>
          <div
            class="box-form select-size has-marketing"
            [class.criteria-selected]="
              isCriteriaSelected('has_marketing_department')
            "
          >
            <div class="multi-box-select">
              <div class="label" [ngClass]="{ disabled: viewMode }">
                Has Marketing Department
              </div>
              <div class="checkbox">
                <ng-container
                  *ngIf="
                    hasMarketingDepartmentList &&
                    hasMarketingDepartmentList.length > 0
                  "
                >
                  <app-tri-state-checkbox
                    [items]="hasMarketingDepartmentList"
                    [savedValues]="
                      funnelForm.get('has_marketing_department')?.value
                    "
                    (valueChange)="
                      setCheckboxList($event, 'has_marketing_department')
                    "
                  ></app-tri-state-checkbox>
                </ng-container>
              </div>
            </div>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="has_marketing_department"
              [positions]="positionValues"
            />
          </div>
          <div
            class="box-form select-size has-analytics"
            [class.criteria-selected]="
              isCriteriaSelected('has_analytics_platform')
            "
          >
            <div class="multi-box-select">
              <div class="label" [ngClass]="{ disabled: viewMode }">
                Has Analytics Platform
              </div>
              <div class="checkbox">
                <ng-container
                  *ngIf="
                    hasAnalyticsPlatformList &&
                    hasAnalyticsPlatformList.length > 0
                  "
                >
                  <app-tri-state-checkbox
                    [items]="hasAnalyticsPlatformList"
                    [savedValues]="
                      funnelForm.get('has_analytics_platform')?.value
                    "
                    (valueChange)="
                      setCheckboxList($event, 'has_analytics_platform')
                    "
                  ></app-tri-state-checkbox>
                </ng-container>
              </div>
            </div>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="has_analytics_platform"
              [positions]="positionValues"
            />
          </div>
          <div
            class="box-form select-size has-website"
            [class.criteria-selected]="isCriteriaSelected('has_website')"
          >
            <div class="multi-box-select">
              <div class="label" [ngClass]="{ disabled: viewMode }">
                Has Website
              </div>
              <div class="checkbox">
                <ng-container
                  *ngIf="hasWebsiteList && hasWebsiteList.length > 0"
                >
                  <app-tri-state-checkbox
                    [items]="hasWebsiteList"
                    [savedValues]="funnelForm.get('has_website')?.value"
                    (valueChange)="setCheckboxList($event, 'has_website')"
                  ></app-tri-state-checkbox>
                </ng-container>
              </div>
            </div>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="has_website"
              [positions]="positionValues"
            />
          </div>
        </div>

        <div class="box-component">
          <div
            class="box-form advertisement"
            [class.criteria-selected]="isCriteriaSelected('ads')"
          >
            <p><label>Advertisement</label></p>
            <ng-container *ngIf="ads && ads.length > 0">
              <app-tri-state-checkbox
                [items]="ads"
                [savedValues]="funnelForm.get('ads')?.value"
                (valueChange)="setCheckboxList($event, 'ads')"
                [showLabel]="true"
              ></app-tri-state-checkbox>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="ads"
              [positions]="positionValues"
            />
          </div>
          <div
            class="box-form ecommerceCms"
            [class.criteria-selected]="isCriteriaSelected('ecommerceCms')"
          >
            <p><label>Ecommerce CMS</label></p>
            <ng-container
              *ngIf="ecommerceCmsList && ecommerceCmsList.length > 0"
            >
              <app-tri-state-checkbox
                [items]="ecommerceCmsList"
                [savedValues]="funnelForm.get('ecommerceCms')?.value"
                (valueChange)="setCheckboxList($event, 'ecommerceCms')"
                [showLabel]="true"
              ></app-tri-state-checkbox>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="ecommerceCms"
              [positions]="positionValues"
            />
          </div>
          <div
            class="box-form social-media"
            [class.criteria-selected]="isCriteriaSelected('social_media')"
          >
            <p><label>Social Media</label></p>
            <ng-container *ngIf="socialMedia && socialMedia.length > 0">
              <app-tri-state-checkbox
                [items]="socialMedia"
                [savedValues]="funnelForm.get('social_media')?.value"
                (valueChange)="setCheckboxList($event, 'social_media')"
                [showLabel]="true"
              ></app-tri-state-checkbox>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="social_media"
              [positions]="positionValues"
            />
          </div>
          <div
            class="box-form email-system"
            [class.criteria-selected]="isCriteriaSelected('email_system')"
          >
            <p><label>E-mail System</label></p>
            <ng-container *ngIf="emailSystemList && emailSystemList.length > 0">
              <app-tri-state-checkbox
                [items]="emailSystemList"
                [savedValues]="funnelForm.get('email_system')?.value"
                (valueChange)="setCheckboxList($event, 'email_system')"
                [showLabel]="true"
              ></app-tri-state-checkbox>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="email_system"
              [positions]="positionValues"
            />
          </div>
          <div
            class="box-form crm-system"
            [class.criteria-selected]="isCriteriaSelected('crm_system')"
          >
            <p><label>CRM System</label></p>
            <ng-container *ngIf="crmSystemList && crmSystemList.length > 0">
              <app-tri-state-checkbox
                [items]="crmSystemList"
                [savedValues]="funnelForm.get('crm_system')?.value"
                (valueChange)="setCheckboxList($event, 'crm_system')"
                [showLabel]="true"
              ></app-tri-state-checkbox>
            </ng-container>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="crm_system"
              [positions]="positionValues"
            />
          </div>
        </div>

        <div class="box-component">
          <div
            class="box-form web-traffic"
            [class.criteria-selected]="
              isCriteriaSelected('no_unique_visitors_avg_3mo')
            "
          >
            <p><label>Web Traffic</label></p>
            <app-range-slider
              class="slider"
              [step]="10000"
              [min]="0"
              [max]="10000000"
              [savedValues]="
                funnelForm.get('no_unique_visitors_avg_3mo')?.value
              "
              (valueChange)="
                sliderValueChange($event, 'no_unique_visitors_avg_3mo', true)
              "
            >
            </app-range-slider>
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="no_unique_visitors_avg_3mo"
              [positions]="positionValues"
            />
          </div>
          <div
            class="box-form number-of-products"
            [class.criteria-selected]="isCriteriaSelected('number_of_products')"
          >
            <p><label>Number of Products</label></p>
            <app-range-slider
              class="slider"
              [step]="10"
              [min]="0"
              [max]="500"
              [savedValues]="funnelForm.get('number_of_products')?.value"
              (valueChange)="
                sliderValueChange($event, 'number_of_products', true)
              "
            />
            <app-criteria-position
              [handleChange]="handlePositionChange"
              filterName="number_of_products"
              [positions]="positionValues"
            />
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="right-side" *ngIf="!loading">
    <h3>Funnel Preview</h3>
    <app-funnel-card
      [name]="funnelForm.controls['name'].value"
      [funnelItem]="funnel"
      [isFunnelPreview]="true"
    />
    <div class="button-container mt-3" *ngIf="!viewMode">
      <button (click)="save()" class="btn btn-primary mb-3">Save funnel</button>
    </div>
  </div>
</div>
