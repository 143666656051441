import { Component, inject, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FunnelModel } from '../../../models/funnel-model';
import { FunnelComponent } from "../funnel/funnel.component";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ExportFunnelComponent } from '../export-funnel/export-funnel.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-funnel-card',
  standalone: true,
  imports: [MatProgressBarModule, FunnelComponent, CommonModule, MatButtonModule, MatMenuModule, MatIconModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule],
  templateUrl: './funnel-card.component.html',
  styleUrl: './funnel-card.component.scss'
})
export class FunnelCardComponent {
  @Input() funnelItem!: FunnelModel;
  readonly dialog = inject(MatDialog);

  constructor(private router: Router) { }

  public conversionRate: string = '0';
  public lostConversionRate: string = '0';
  public imgPath = './../../../../assets/images/icons/';
  public totalLeads: string = "0";
  public exportedLeads: string = "0";

  ngOnInit(): void {
    this.conversionRate = this.funnelItem.conversion_rate ? (this.funnelItem.conversion_rate * 100).toFixed(0) : '0';
    this.lostConversionRate = this.funnelItem.lost_conversion_rate ? (this.funnelItem.lost_conversion_rate * 100).toFixed(0) : '0';
    this.getExportedLeads();
  }

  getExportedLeads() {
    let leads = this.funnelItem.criterias[0]?.counter || 0;
    let exported = this.funnelItem.exported || 0;
    this.totalLeads = new Intl.NumberFormat('de-DE').format(leads);
    this.exportedLeads = new Intl.NumberFormat('de-DE').format(exported);
  }

  openFunnelExport(): void {
    this.dialog.open(ExportFunnelComponent, {
      data: this.funnelItem,
    });
  }

  viewFunnel() {
    this.router.navigate([`/funnel-management/${this.funnelItem._id}`]);
  }

}