<div class="treeview" [ngClass]="{ 'one-row': items.length < 2}">
  <ng-container *ngFor="let item of items">
    <div class="parent-item" [ngClass]="{'show-label': showLabel}">
      @if(showLabel){
        <div class="mdc-label" [ngStyle]="{'font-weight': 400, 'font-size': '14px'}">{{item.label}}</div>
      }
      <mat-checkbox
        #checkboxNo
        class="hintly-checkbox"
        [disabled]="disabled"
        [indeterminate]="selectedItems[item.value] == states.OPPOSITE"
        [ngClass]="{ 'opposite-state': selectedItems[item.value] === states.OPPOSITE }"
        (change)="onItemChange(item.value, checkboxNo, checkboxYes, true)">
        No
      </mat-checkbox>
      <mat-checkbox
        #checkboxYes
        class="hintly-checkbox"
        [disabled]="disabled"
        [checked]="selectedItems[item.value] === states.CHECKED"
        [ngClass]="{ 'opposite-state': selectedItems[item.value] === states.OPPOSITE }"
        (change)="onItemChange(item.value, checkboxNo, checkboxYes, false)">
        Yes
      </mat-checkbox>
    </div>
  </ng-container>
</div>
