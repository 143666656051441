import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-hintly-select',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hintly-select.component.html',
  styleUrl: './hintly-select.component.scss'
})

export class HintlySelectComponent {
  @Input() options: any[] = [];
  @Input() handleChange!: Function;
  @Input() filterName!: string;

  onSelectionChange(event: any) {
    this.handleChange(event.target.value, this.filterName);
  }
}
