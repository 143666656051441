import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatIconModule} from '@angular/material/icon';
import {AsyncPipe} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';


@Component({
  selector: 'app-auto-complete-chips',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  templateUrl: './auto-complete-chips.component.html',
  styleUrl: './auto-complete-chips.component.scss'
})
export class AutoCompleteChipsComponent {
  optionCtrl = new FormControl('');
  filteredOptions: Observable<string[]>;
  options: string[] = [];
  @Input() allOptions: string[] = [];

  @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement> | undefined;
  @Output() currentSelection = new EventEmitter<string[]>();

  constructor() {
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      startWith(null),
      map((option: string | null) => (option ? this._filter(option) : this.allOptions.slice())),
    );
  }

  remove(option: string): void {
    const index = this.options.indexOf(option);

    if (index >= 0) {
      this.options.splice(index, 1);
    }
    this.currentSelection.emit(this.options)
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const hasOptionValue = this.allOptions.some((option) => option === event.option.viewValue);
    const hasValue = this.options.some((option) => option === event.option.viewValue);

    if (hasOptionValue && !hasValue) {
      this.options.push(event.option.viewValue);
    }

    if (this.optionInput && hasOptionValue && !hasValue) {
      this.optionInput.nativeElement.value = '';
    }
    this.optionCtrl.setValue(null);
    this.currentSelection.emit(this.options);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  forceOptionCtrlChange(): void {
    this.optionCtrl.setValue(this.optionInput?.nativeElement.value || "");
  }
}
