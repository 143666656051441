import { ItemModel } from "../models/custom/tree-view-model";

const HINTLY_COMPANY_TYPE_MODELS_MAP: { [key: string]: ItemModel[] } = {
  "Limited": [
    {name: "Private limited company", value: "Private limited company", label: "Private limited company", subItems: [], inverse: false},
    {name: "Public limited company", value: "Public limited company", label: "Public limited company", subItems: [], inverse: false},
    {name: "Limited partnership with shares", value: "Limited partnership with shares", label: "Limited partnership with shares", subItems: [], inverse: false},
    {name: "Limited partnership", value: "Limited partnership", label: "Limited partnership", subItems: [], inverse: false},
    {name: "Association or company with limited liability", value: "Association or company with limited liability", label: "Association or company with limited liability", subItems: [], inverse: false},
    {name: "Branch of a foreign public limited company or limited partnership with shares", value: "Branch of a foreign public limited company or limited partnership with shares", label: "Branch of a foreign public limited company or limited partnership with shares", subItems: [], inverse: false},
    {name: "Branch of a foreign private limited company or company", value: "Branch of a foreign private limited company or company", label: "Branch of a foreign private limited company or company", subItems: [], inverse: false},
    {name: "Branch of a foreign limited liability company", value: "Branch of a foreign limited liability company", label: "Branch of a foreign limited liability company", subItems: [], inverse: false},
    {name: "Branch of a European Company (SE)", value: "Branch of a European Company (SE)", label: "Branch of a European Company (SE)", subItems: [], inverse: false},
    {name: "Greenlandic branch of a foreign company or business", value: "Greenlandic branch of a foreign company or business",  label: "Greenlandic branch of a foreign company or business", subItems: [], inverse: false},
    {name: "Special financial enterprise", value: "Special financial enterprise", label: "Special financial enterprise", subItems: [], inverse: false}
  ],
  "Sole Proprietaries": [
    {name: "Sole proprietorship", value: "Sole proprietorship", label: "Sole proprietorship", subItems: [], inverse: false},
    {name: "Personally owned small business", value: "Personally owned small business", label: "Personally owned small business", subItems: [], inverse: false},
    {name: "Entrepreneurial company (Terminated)", value: "Entrepreneurial company (Terminated)", label: "Entrepreneurial company (Terminated)", subItems: [], inverse: false},
  ],
  "Unions": [
    {name: "Association with limited liability", value: "Association with limited liability", label: "Association with limited liability", subItems: [], inverse: false},
    {name: "Cooperative society (cooperative association)", value: "Cooperative society (cooperative association)", label: "Cooperative society (cooperative association)", subItems: [], inverse: false},
    {name: "Voluntary association", value: "Voluntary association", label: "Voluntary association", subItems: [], inverse: false},
    {name: "Association", value: "Association", label: "Association", subItems: [], inverse: false},
    {name: "Employee investment company", value: "Employee investment company", label: "Employee investment company", subItems: [], inverse: false},
  ],
  "Others": [
    {name: "Region (administrative division)", value: "Region (administrative division)", label: "Region (administrative division)", subItems: [], inverse: false},
    {name: "Ecclesiastical institutions", value: "Ecclesiastical institutions", label: "Ecclesiastical institutions", subItems: [], inverse: false},
    {name: "Primary municipality", value: "Primary municipality", label: "Primary municipality", subItems: [], inverse: false},
    {name: "Independent public enterprise", value: "Independent public enterprise", label: "Independent public enterprise", subItems: [], inverse: false},
    {name: "State administrative unit", value: "State administrative unit", label: "State administrative unit", subItems: [], inverse: false},
    {name: "Foundations and other self-owning institutions", value: "Foundations and other self-owning institutions", label: "Foundations and other self-owning institutions", subItems: [], inverse: false},
    {name: "Business-driven foundation", value: "Business-driven foundation", label: "Business-driven foundation", subItems: [], inverse: false},
    {name: "Estate of a deceased person", value: "Estate of a deceased person", label: "Estate of a deceased person", subItems: [], inverse: false},
    {name: "Partnership shipping company", value: "Partnership shipping company", label: "Partnership shipping company", subItems: [], inverse: false},
    {name: "Unspecified business type", value: "Unspecified business type", label: "Unspecified business type", subItems: [], inverse: false},
    {name: "Other foreign business", value: "Other foreign business", label: "Other foreign business", subItems: [], inverse: false},
    {name: "European Economic Grouping", value: "European Economic Grouping", label: "European Economic Grouping", subItems: [], inverse: false},
    {name: "Permanent establishment of a European Economic Grouping", value: "Permanent establishment of a European Economic Grouping", label: "Permanent establishment of a European Economic Grouping", subItems: [], inverse: false},
    {name: "Limited liability company (Terminated)", value: "Limited liability company (Terminated)", label: "Limited liability company (Terminated)", subItems: [], inverse: false},
    {name: "European Company (SE) (Terminated)", value: "European Company (SE) (Terminated)", label: "European Company (SE) (Terminated)", subItems: [], inverse: false},
  ],
}

export default function mapCompanyTypeModels(models: ItemModel[]): ItemModel[] {
  if (!models || models.length === 0) {
    return [];
  }

  const flatModels =  models.map((model) => {
    const mappedModels = HINTLY_COMPANY_TYPE_MODELS_MAP[model.name];
    if (model.inverse) {
      return mappedModels.map((mappedModel) => {
        return {
          ...mappedModel,
          inverse: true
        }
      }
      );
    }
    return mappedModels;
  }).flat();

  return flatModels;
}
