<mat-slider
  [disabled]="disabled"
  class="hintly-slider"
  discrete
  (change)="onInputChange()"
  [step]="step"
  [min]="min"
  [max]="max"
  [displayWith]="formatLabel"
>
  <input [value]="minValue" #start matSliderStartThumb />
  <input [value]="maxValue" #end matSliderEndThumb />
</mat-slider>
<div class="range">
  <div class="min-value">{{ isDate ? minValue : minValue | number: '1.0-0': 'da' }} {{ pattern }}</div>
  <div class="max-value">{{ isDate ? maxValue : maxValue | number: '1.0-0': 'da' }} {{ pattern }}</div>
</div>
