<div *ngIf="isLoggedIn()" class="header-wrapper w-100">
  <div class="header-content d-flex justify-content-between align-items-center">
    <div class="logo-wrapper">
      <a href="#" (click)="openExitDialog($event, '/dashboard')">
        <img src="../../../../assets/images/icons/hintly-logo.png" alt="hintly-logo" referrerpolicy="no-referrer"/>
      </a>
    </div>
    <div class="d-flex">
      <p class="credits" *ngIf="totalCredits > 0 && usedCredits > 0">Credits usage: <span class="usage">300 / 10.000</span></p>
      <div class="image">
        <a routerLink="/funnel-management/">
          <div class="button-container">
            <button class="btn btn-primary">
              <img src="../../../../assets/images/icons/plus.png" width="20" alt="Create Funnel Icon"/>
              Create a new funnel
            </button>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
