import { Component, computed, Signal } from '@angular/core';
import { AuthStatusService } from '../../../services/auth/auth-status.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  isLoggedIn:Signal<boolean> = computed(() => this.authStatusService.isLoggedIn());
  // Only to have a way to tell that we don't support the credit usage yet
  totalCredits: number = -1;
  usedCredits: number = -1;

  constructor(private authStatusService: AuthStatusService) {}
}
