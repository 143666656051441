<div class="funnel-progress d-flex d-none">
  <div class="bar d-flex flex-column align-items-center">
    <div class="label-top d-flex justify-content-between">
      <div>Exploration</div>
      <div>2nd adjustment</div>
    </div>
    <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
    <div class="label-bot d-flex justify-content-between">
      <div>1st adjustment</div>
      <div>Scaling</div>
    </div>
  </div>
  <div></div>
  <img
    src="assets/images/icons/export-icon.svg"
    alt="eye export icon"
    class="export-icon"
  />
</div>
