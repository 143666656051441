import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { RegularSliderEvent } from '../../../models/regular-slider-event';

@Component({
  selector: 'app-regular-slider',
  standalone: true,
  imports: [CommonModule, MatSliderModule],
  templateUrl: './regular-slider.component.html',
  styleUrl: './regular-slider.component.scss'
})
export class RegularSliderComponent {
  @Input() min!: number;
  @Input() max!: number;
  @Input() pattern: string = "";
  @Input() disabled: boolean = false;
  @Input() step: number = 50;
  @ViewChild('regularSliderInput') regularSliderInput!: ElementRef;

  value!: number;
  minValue!: number;
  maxValue!: number;

  @Output() valueChange = new EventEmitter<RegularSliderEvent>();

  ngOnChanges() {
    this.minValue = this.min;
    this.maxValue = this.max;
  }

  onInputChange() {
    this.value = +this.regularSliderInput?.nativeElement?.value;
    this.valueChange.emit({
      value: this.value?.toString()
    });
  }
}
