<div class="treeview">
  <ng-container *ngFor="let item of items">
    <ng-container *ngIf="shouldDisplayItem(item.value)">
      <div class="parent-item">
        <i
          *ngIf="item.subItems && item.subItems.length > 0"
          class="chevron"
          [ngClass]="expandedItems[item.value] ? 'chevron-down' : 'chevron-right'"
          (click)="toggleExpand(item.value)">
        </i>

          <mat-checkbox
            #checkbox
            *ngIf="!hideCheckbox"
            class="hintly-checkbox"
            [disabled]="disabled"
            [checked]="isCompleteSubLevel(item || [])"
            [indeterminate]="isPartiallySubLevel(item || [])"
            (change)="onParentChange(item.value, checkbox)"
            [value]="item.value"
            [ngClass]="{ 'opposite-state': selectedItems[item.value] === states['OPPOSITE'] }"
            >
            {{ item.label }}
          </mat-checkbox>
          <ng-container *ngIf="hideCheckbox">
            <span class="display-text" [ngClass]="disabled ? 'disabled' : ''">{{ item.label }}</span>
          </ng-container>
        <div *ngIf="expandedItems[item.value]" class="sub-tree">
          <ng-container *ngIf="item.subItems">
            <app-tree-view
              [disabled]="disabled"
              [items]="item.subItems"
              [selectedItems]="selectedItems"
              (valueChange)="onChildValueChange(item.value, $event)"
              inderteminateDisabled="this.indeterminateDisabled"
              [parentItems]="parentItems"
            ></app-tree-view>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
