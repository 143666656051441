import { CommonModule } from '@angular/common';
import { Component, computed, Input, Signal } from '@angular/core';
import { FunnelFormPosition } from '../../../models/funnel-management-models';

@Component({
  selector: 'app-criteria-position',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './criteria-position.component.html',
  styleUrl: './criteria-position.component.scss'
})
export class CriteriaPositionComponent {
  @Input() handleChange!: Function;
  @Input() filterName!: string;
  @Input() positions!: Signal<FunnelFormPosition>;
  computedPositions!: Signal<FunnelFormPosition>;
  closeButton: boolean = false
  

  ngOnInit() {
    this.computedPositions = computed(() => this.positions());
  }

  showCloseButton(close: boolean){
    if(this.computedPositions()[this.filterName]){
      this.closeButton = close
    }
  }

  handleUpdate() {
    if(this.computedPositions()[this.filterName]){
      this.handleChange(this.computedPositions()[this.filterName], this.filterName);
      this.closeButton = false;
    }
  }
}
