import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';
import { FunnelService } from '../../../../services/funnel/funnel.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { SpinnerService } from '../../../../services/core/spinner/spinner.service';
import { FunnelCardComponent } from '../../../custom/funnel-card/funnel-card.component';
import { FunnelModel } from '../../../../models/funnel-model';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [RouterModule, MatButtonModule, MatDividerModule, MatIconModule, CommonModule, FunnelCardComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  providers: [AuthService, FunnelService],
})
export class DashboardComponent {
  funnels: FunnelModel[] = [];

  constructor(
    private spinnerService: SpinnerService,
    private funnelService: FunnelService,
    ) {}

  ngOnInit(): void {
    this.loadFunnel();
  }

  loadFunnel(): void {
    this.spinnerService.show();
    this.funnelService.getFunnelList().subscribe(data => {
      this.funnels = data;
      this.spinnerService.hide();
    });
  }
}
