<div class="funnel-card-container d-flex flex-column justify-content-evenly">
  <div class="funnel-header">
    <div class="d-flex justify-content-between">
      <h2 class="title">
        {{ funnelItem.name }}
      </h2>
      <div class="actions d-flex justify-content-end align-items-center">
        <img src="./../../../../assets/images/icons/tree-dots.svg" alt="edit icon" class="edit-icon pe-3">
        <img *ngIf="!funnelItem.is_favorite" src="./../../../../assets/images/icons/star-unselected.svg" alt="favorite icon" class="fav-icon">
        <img *ngIf="funnelItem.is_favorite" src="./../../../../assets/images/icons/star-selected.svg" alt="favorite icon" class="fav-icon">
      </div>
    </div>
    <hr class="line">
  </div>
  <div class="funnel-progress d-flex">
    <div class="bar d-flex flex-column align-items-center">
      <div class="label-top d-flex justify-content-between">
        <div >Exploration</div>
        <div>2nd adjustment</div>
      </div>
      <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
      <div class="label-bot d-flex justify-content-between">
        <div>1st adjustment</div>
        <div>Scaling</div>
      </div>
    </div>
    <div>

    </div>
    <img src="./../../../../assets/images/icons/export-icon.svg" alt="eye export icon" class="export-icon">
  </div>
  <app-funnel [funnelItems]="funnelItem.criterias"></app-funnel>
  <div class="funnel-metrics d-flex">
    <div class="circle-chart">
      <svg viewBox="0 0 36 36" class="circular-chart">
        <path class="circle-bg"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"/>
        <path class="circle"
              [attr.stroke-dasharray]="conversionRate + ',100'"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"/>
      </svg>
      <div class="circle-label">
        <div class="percentage">{{ conversionRate }}%</div>
        <div class="subtext">Meeting booked <br>Conversion rate</div>
      </div>
    </div>
    <div class="circle-chart">
      <svg viewBox="0 0 36 36" class="circular-chart">
        <path class="circle-bg"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"/>
        <path class="circle lost"
              [attr.stroke-dasharray]="lostConversionRate + ',100'"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"/>
      </svg>
      <div class="circle-label">
        <div class="percentage lost">{{ lostConversionRate }}%</div>
        <div class="subtext">Won/Lost <br>Conversion rate</div>
      </div>
    </div>
  </div>
</div>

