<div 
  class="level-indicator" 
  (click)="toggleLevelUpdateBox()" 
  id="level-indicator-{{filterName}}"
  [ngClass]="{ 'disabled': disabled }"
>
  <div class="text">{{ computedPositions()[filterName] }}</div>
</div>

<div class="level-update level-box-hidden" id="level-update-{{filterName}}">
  <div *ngFor="let pos of computedAvailablePositions()"
        class="text"
        [class.disabled]="!pos.available || disabled"
        (click)="handleUpdate(pos.index, filterName, pos.available)">
    {{ pos.index }}
  </div>
</div>
