import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

interface option {
  value: any;
  viewValue: string;
}

@Component({
  selector: 'app-multiple-select',
  standalone: true,
  imports: [MatSelectModule, MatCheckboxModule], // Removed FormsModule and ReactiveFormsModule
  templateUrl: './multiple-select.component.html',
  styleUrl: './multiple-select.component.scss'
})
export class MultipleSelectComponent {
  @Input() options: option[] = [];
  @Output() selectionChange = new EventEmitter<any[]>();

  selectedOptions: any[] = [];

  constructor() { }

  ngOnInit(): void { }

  onSelectionChange(event: any) {
    this.selectedOptions = event.value;
    console.log('Selected options:', this.selectedOptions);
    this.selectionChange.emit(this.selectedOptions);
  }
}
