import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FilterModel, FunnelItemModel, FunnelModel } from '../../models/funnel-model';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { FilterMapping, MappingType } from '../../models/filter-mapping-model';

@Injectable({
  providedIn: 'root'
})
export class FunnelService {
  private fieldsMap: FilterMapping[] = [
    { source: 'industry', dest: 'h_Industry', type: MappingType.MULTIVALUED },
    { source: 'number_of_employees', dest: 'h_NumberOfEmployees', type: MappingType.RANGE },
    { source: 'gross_results', dest: 'h_fin_1PYGrossProfit', type: MappingType.RANGE },
    { source: 'is_ecommerce', dest: 'h_IsEcommerce', type: MappingType.BOOL },
    { source: 'has_cms', dest: 'h_HasCmsPlatform', type: MappingType.BOOL },
    { source: 'has_analytics', dest: 'h_HasAnalyticsPlatform', type: MappingType.BOOL },
    { source: 'has_ecommerce', dest: 'h_HasEcommercePlatform', type: MappingType.BOOL },
    { source: 'has_advertising', dest: 'h_HasAdvertisingPlatform', type: MappingType.BOOL },
    { source: 'has_mkt_automation', dest: 'h_HasMktAutomationPlatform', type: MappingType.BOOL },

    { source: 'umbraco', dest: 'h_IsUmbraco', type: MappingType.BOOL },
    { source: 'shopify', dest: 'h_IsShopify', type: MappingType.BOOL },
    { source: 'magento', dest: 'h_IsMagento', type: MappingType.BOOL },
    { source: 'woocommerce', dest: 'h_IsWooCommerce', type: MappingType.BOOL },
    { source: 'prestashop', dest: 'h_IsPrestaShop', type: MappingType.BOOL },
    { source: 'ecommerceCms', dest: '', type: MappingType.CHECKLIST },
    { source: 'amazon_ads', dest: 'h_IsAmazonAds', type: MappingType.BOOL },
    { source: 'google_ads', dest: 'h_IsGoogleAds', type: MappingType.BOOL },
    { source: 'facebook_ads', dest: 'h_IsFacebookAds', type: MappingType.BOOL },
    { source: 'twitter_ads', dest: 'h_IsTwitterAds', type: MappingType.BOOL },
    { source: 'linkedin_ads', dest: 'h_IsLinkedinAds', type: MappingType.BOOL },
    { source: 'ms_ads', dest: 'h_IsMicrosoftAds', type: MappingType.BOOL },
    { source: 'platform', dest: '', type: MappingType.CHECKLIST },
    { source: 'ads', dest: '', type: MappingType.CHECKLIST },
    { source: 'social_media', dest: '', type: MappingType.CHECKLIST },
    { source: 'crm_system', dest: '', type: MappingType.CHECKLIST },
    { source: 'company_type', dest: 'h_CompanyType', type: MappingType.MULTIVALUED },
    { source: 'email_system', dest: '', type: MappingType.CHECKLIST },
    { source: 'has_marketing_department', dest: 'h_HasMarketingDepartment', type: MappingType.BOOL },
    { source: 'facebook_url', dest: 'h_some_FacebookURL', type: MappingType.BOOL },
    { source: 'instagram_url', dest: 'h_InstagramURL', type: MappingType.BOOL },
    { source: 'tiktok_url', dest: 'h_TiktokURL', type: MappingType.BOOL },
    { source: 'linkedin_url', dest: 'h_LinkedinURL', type: MappingType.BOOL },
    { source: 'has_hubspot', dest: 'h_HasHubspot', type: MappingType.BOOL },
    { source: 'has_salesforce', dest: 'h_HasSalesforce', type: MappingType.BOOL },
    { source: 'has_pipedrive', dest: 'h_HasPipedrive', type: MappingType.BOOL },
    { source: 'incorporation_date', dest: 'h_DateOfIncorporation', type: MappingType.RANGE },
    { source: 'no_unique_visitors_avg_3mo', dest: 'h_NoUniqueVisitorsAvg3mo', type: MappingType.RANGE },
  ];
  private readonly funnelUrl = 'assets/mocks/fake-get-funnel.json';
  constructor(private http: HttpClient, private authService: AuthService) { }

  getFunnel(id: number): Observable<FunnelModel> {
    return this.http.get<FunnelModel>(this.funnelUrl);
  }

  getFunnelList(): Observable<FunnelModel[]> {
    let endpointUrl = environment.HINTLY_BE_URL + '/api/funnels/' + this.authService.getCustomerName();
    return this.http.get<{ data: FunnelModel[] }>(endpointUrl).pipe(
      map((response) => {
        return response.data?.sort((a, b) => a.created_at! > b.created_at! ? -1 : 1);
      }));
  }

  create(request: FunnelModel): Observable<FunnelModel> {
    let endpointUrl = environment.HINTLY_BE_URL + '/api/funnels/' + this.authService.getCustomerName();
    return this.http.post<{ data: FunnelModel }>(endpointUrl, request).pipe(
      map((response) => {
        return response.data;
      }));
  }

  countFunnelMatches(criterias: FunnelItemModel[]): any {
    const filterObj = this.getFilterForCriterias(criterias);
    let endpointUrl = environment.HINTLY_MASTER_URL + '/api/count';
    return { response: this.http.post<number>(endpointUrl, { filter: filterObj }), query: filterObj };
  }


  getFilterForCriterias(criterias: FunnelItemModel[]): any {
    const criteriasList: any[] = [];
    for (const criteria of criterias ?? []) {
      if (criteria.filters.length == 1) {
        const translated = this.translateToHintlyFilters(criteria.filters[0]);
        if (translated) {
          if (translated.name.length > 0)
            criteriasList.push({ [translated.name]: translated.values[0] });
          else
            criteriasList.push(translated.values[0]);
        }
      } else if (criteria.filters.length > 1) {
        const filtersList: any[] = [];
        for (const filter of criteria.filters) {
          const translated = this.translateToHintlyFilters(filter);
          if (translated) {
            if (translated.name.length > 0)
              filtersList.push({ [translated.name]: translated.values[0] });
            else
              filtersList.push(translated.values[0]);
          }
        }
        criteriasList.push({ '$and': filtersList });
      }
    }
    const filterObj = { '$and': criteriasList };
    console.log(JSON.stringify(filterObj));
    return filterObj;
  }

  translateToHintlyFilters(filter: FilterModel): FilterModel | null {
    const matchedMapping = this.getMapping(filter.name);
    if (!matchedMapping) {
      return null;
    }
    let generatedFilter = null;
    if (matchedMapping.type == MappingType.MULTIVALUED) {
      const valuesArr: any[] = [];
      for (const value of filter.values) {
        valuesArr.push(value.name ? value.name : value);
      }
      generatedFilter = { '$in': valuesArr };
    } else if (matchedMapping.type == MappingType.RANGE) {
      generatedFilter = { '$gte': parseInt(filter.values[0]), '$lte': parseInt(filter.values[1]) };
    } else if (matchedMapping.type == MappingType.BOOL) {
      generatedFilter = { '$eq': filter.values[0] };
    } else if (matchedMapping.type == MappingType.CHECKLIST) {
      const conditions: any[] = [];
      for (const value of filter.values) {
        const mapped = this.getMapping(value.name);
        if (!mapped) continue;
        conditions.push({ [mapped.dest]: value.value });
      }
      generatedFilter = { '$or': conditions };
    }

    const filterObj: FilterModel = { name: matchedMapping.dest, values: [generatedFilter] };
    return filterObj;
  }

  getMapping(key: string): FilterMapping | null {
    const matchedMapping = this.fieldsMap.filter(map => map.source == key);
    if (matchedMapping.length == 0) {
      return null;
    }
    return matchedMapping[0];
  }
}
