import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { ExportFunnelComponent } from '../../export-funnel/export-funnel.component';
import { ExportFunnelHistoryComponent } from '../../export-funnel-history/export-funnel-history.component';
import { MatDialog } from '@angular/material/dialog';
import { FunnelModel } from '../../../../models/funnel-model';
import { FunnelService } from '../../../../services/funnel/funnel.service';

@Component({
  selector: 'app-funnel-card-header',
  standalone: true,
  imports: [MatMenuModule],
  templateUrl: './funnel-card-header.component.html',
  styleUrl: './funnel-card-header.component.scss',
})
export class FunnelCardHeaderComponent implements OnInit {
  @Input() funnelItem!: FunnelModel;
  @Input() name?: string;
  @Output() funnelExported = new EventEmitter<boolean>();
  funnelService: FunnelService = inject(FunnelService);
  dialog: MatDialog = inject(MatDialog);
  public totalLeads = '0';
  public exportedLeads = '0';

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (!this.name) {
      this.name = this.funnelItem.name;
    }
    this.getExportedLeads();
    console.log(this.funnelItem);
  }

  getExportedLeads() {
    const leads = this.funnelItem.criterias[0]?.counter || 0;
    const exported = this.funnelItem.exported || 0;
    this.totalLeads = new Intl.NumberFormat('de-DE').format(leads);
    this.exportedLeads = new Intl.NumberFormat('de-DE').format(exported);
  }

  openFunnelExport(): void {
    this.dialog
      .open(ExportFunnelComponent, {
        data: this.funnelItem,
      })
      .afterClosed()
      .subscribe(() => {
        this.funnelExported.emit(true);
      });
  }

  openFunnelExportHistory(): void {
    this.dialog.open(ExportFunnelHistoryComponent, {
      data: this.funnelItem,
    });
  }

  favoriteFunnel() {
    this.funnelService
      .favoriteFunnel(this.funnelItem._id!, !this.funnelItem.is_favorite)
      .subscribe({
        next: (funnel) => {
          this.funnelItem = funnel;
        },
      });
  }
}
