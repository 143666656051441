<div class="treeview">
  <ng-container *ngFor="let item of items">
     <div class="parent-item">
      <i
        *ngIf="item.subItems"
        class="chevron"
        [ngClass]="expandedItems[item.value] ? 'chevron-down' : 'chevron-right'"
        (click)="toggleExpand(item.value)">
      </i>
      <mat-checkbox
        class="hintly-checkbox"
        [checked]="selectedItems[item.value]"
        [indeterminate]="indeterminateItems[item.value]"
        (change)="onParentChange(item.value, $event.checked)"
        [value]="item.value">
        {{ item.label }}
      </mat-checkbox>

      <div *ngIf="expandedItems[item.value]" class="sub-tree">
        <ng-container *ngIf="item.subItems">
          <app-tree-view
            [items]="item.subItems"
            [selectedItems]="selectedItems"
            [indeterminateItems]="indeterminateItems"
            (valueChange)="onChildValueChange(item.value, $event)">
          </app-tree-view>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>