@if(isLoggedIn()){
  <div class="header-wrapper w-100 bg-white">
    <div class="header-content d-flex justify-content-between align-items-center">
      <div class="logo-wrapper">
        <a href="#" (click)="openExitDialog($event, '/dashboard')">
          <img src="../../../../assets/images/icons/hintly-logo.png" alt="hintly-logo" referrerpolicy="no-referrer"/>
        </a>
      </div>
      <div class="d-flex">
        <p class="credits">Credits: <span class="usage">{{ credits() }}</span></p>
      </div>
    </div>
  </div>  
}