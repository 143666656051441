import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AuthStatusService {
  public isLoggedIn: WritableSignal<boolean> = signal(false);

  setLoggedIn(value: boolean) {
    this.isLoggedIn.set(value);
  }
}
