import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ItemModel, SubItemModel } from '../../../models/custom/tree-view-model';

@Component({
  selector: 'app-tree-view',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, FormsModule, ReactiveFormsModule],
  templateUrl: './tree-view.component.html',
  styleUrl: './tree-view.component.scss'
})
export class TreeViewComponent implements OnInit {
  @Input() items!: ItemModel[];
  @Input() selectedItems: { [key: string]: boolean } = {};
  @Input() indeterminateItems: { [key: string]: boolean } = {};
  @Input() disabled: boolean = false;
  @Input() savedValues!: ItemModel[];
  @Input() hideCheckbox: boolean = false;
  @Input() displayOnlySavedValues: boolean = false;
  @Output() valueChange = new EventEmitter<ItemModel[]>();

  expandedItems: { [key: string]: boolean } = {};

  constructor() {}

  ngOnInit(): void {
    this.setSavedValues(this.savedValues);
    this.initializeItems(this.items);
  }

  setSavedValues(items: ItemModel[]) {
    items?.forEach(item => {
      this.selectedItems[item.value] = true;
      this.updateParentStateRecursively(item);
      this.updateParentIndeterminateState();
    });
  }

  initializeItems(items: ItemModel[]) {
    items.forEach(item => {
      this.selectedItems[item.value] = this.selectedItems[item.value] || false;
      this.indeterminateItems[item.value] = this.indeterminateItems[item.value] || false;

      if (item.subItems) {
        this.initializeItems(item.subItems);
      }
    });
  }

  onParentChange(itemValue: string, isChecked: boolean) {
    this.selectedItems[itemValue] = isChecked;
    this.indeterminateItems[itemValue] = false;

    const item = this.findItem(this.items, itemValue);
    if (item && item.subItems) {
      this.updateChildrenSelection(item.subItems, isChecked);
    }
  
    this.updateParentIndeterminateState();
  
    this.valueChange.emit(this.getSelectedValues());
  }

  updateParentState(parentItemValue: string) {
    const parentItem = this.findItem(this.items, parentItemValue);
    if (parentItem && parentItem.subItems) {
      const allSelected = parentItem.subItems.every(subItem => this.selectedItems[subItem.value]);
      const someSelected = parentItem.subItems.some(subItem => this.selectedItems[subItem.value]);
  
      this.selectedItems[parentItemValue] = allSelected;
      this.indeterminateItems[parentItemValue] = !allSelected && someSelected;
    }
  }

  updateParentIndeterminateState() {
    this.items.forEach(item => this.updateParentStateRecursively(item));
  }
  
  updateParentStateRecursively(item: ItemModel) {
    if (item.subItems) {
      item.subItems.forEach(subItem => {
        if (subItem.subItems) {
          this.updateParentStateRecursively(subItem);
        }
      });
  
      const allSelected = item.subItems.every(subItem => this.selectedItems[subItem.value]);
      const someSelected = item.subItems.some(subItem => this.selectedItems[subItem.value] || this.indeterminateItems[subItem.value]);
  
      this.selectedItems[item.value] = allSelected;
      this.indeterminateItems[item.value] = !allSelected && someSelected;
    }
  }

  onChildValueChange(parentItemValue: string, updatedItems: ItemModel[]) {
    this.updateParentIndeterminateState();
    this.valueChange.emit(this.getSelectedValues());
  }

  onChildChange(childValue: string, parentValue: string, isChecked: boolean) {
    this.selectedItems[childValue] = isChecked;
  
    const parentItem = this.findItem(this.items, parentValue);
    if (parentItem && parentItem.subItems) {
      const allSelected = parentItem.subItems.every(subItem => this.selectedItems[subItem.value]);
      const someSelected = parentItem.subItems.some(subItem => this.selectedItems[subItem.value] || this.indeterminateItems[subItem.value]);
  
      this.selectedItems[parentValue] = allSelected;
      this.indeterminateItems[parentValue] = !allSelected && someSelected;
    }
  
    this.updateParentIndeterminateState();
    this.valueChange.emit(this.getSelectedValues());
  }

  updateChildrenSelection(subItems: SubItemModel[], isChecked: boolean) {
    subItems.forEach(subItem => {
      this.selectedItems[subItem.value] = isChecked;
  
      if (subItem.subItems) {
        this.updateChildrenSelection(subItem.subItems, isChecked);
      }
    });
  }

  findItem(items: ItemModel[], value: string): ItemModel | undefined {
    for (const item of items) {
      if (item.value === value) {
        return item;
      }
      if (item.subItems) {
        const found = this.findItem(item.subItems, value);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  toggleExpand(itemValue: string) {
    this.expandedItems[itemValue] = !this.expandedItems[itemValue];
  }

  getSelectedValues(): ItemModel[] {
    return this.items
      .filter(item => this.selectedItems[item.value] || 
        (item.subItems && this.hasSelectedChildren(item.subItems)))
      .map(item => ({
        name: item.name,
        value: item.value,
        label: item.label,
        subItems: item.subItems
          ? this.getSelectedValuesForChildren(item.subItems)
          : []
      }));
  }

  getSelectedValuesForChildren(subItems: SubItemModel[]): SubItemModel[] {
    return subItems
      .filter(subItem => this.selectedItems[subItem.value] || 
        (subItem.subItems && this.hasSelectedChildren(subItem.subItems)))
      .map(subItem => ({
        name: subItem.name,
        value: subItem.value,
        label: subItem.label,
        subItems: subItem.subItems
          ? this.getSelectedValuesForChildren(subItem.subItems)
          : []
      }));
  }

  hasSelectedChildren(subItems: SubItemModel[]): boolean {
    return subItems.some(subItem => this.selectedItems[subItem.value] || 
      (subItem.subItems && this.hasSelectedChildren(subItem.subItems)));
  }

  shouldDisplayItem(itemValue: string): boolean {
    return (
      (this.indeterminateItems[itemValue] || 
       this.selectedItems[itemValue] && this.displayOnlySavedValues && this.disabled) || 
      (!this.displayOnlySavedValues || !this.disabled)
    );
  }
  
}