import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { ItemModel, SubItemModel } from '../../../models/custom/tree-view-model';
import { CHECKBOX_STATES } from '../../../models/constants/checkbox-states';

@Component({
  selector: 'app-tree-view',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, FormsModule, ReactiveFormsModule],
  templateUrl: './tree-view.component.html',
  styleUrl: './tree-view.component.scss'
})
export class TreeViewComponent implements OnInit {
  @Input() items!: ItemModel[];
  @Input() parentItems!: ItemModel[];
  @Input() selectedItems: { [key: string]: number } = {};
  @Input() disabled: boolean = false;
  @Input() savedValues!: ItemModel[];
  @Input() hideCheckbox: boolean = false;
  @Input() displayOnlySavedValues: boolean = false;
  @Input() inderteminateDisabled: string = "";
  @Input() searchable: boolean = false;
  @Input() expandedItems: { [key: string]: boolean } = {};
  @Input() filterPlaceholder: string = 'Filter items...';
  @Output() valueChange = new EventEmitter<ItemModel[]>();

  public states: { [key: string]: number } = CHECKBOX_STATES;
  filterText: string = '';
  filteredItems: ItemModel[] = [];

  constructor() {}

  ngOnInit(): void {
    this.setSavedValues(this.savedValues);
    this.initializeItems(this.items);
    if (!this.expandedItems) {
      this.expandedItems = {}; // Initialize empty at top level
    }
    this.filteredItems = [...this.items];
    this.onFilterChange();
  }

  setSavedValues(items: ItemModel[]) {
    if (this.savedValues) {
      this.savedValues.forEach(item => {
        if (!item.inverse)
          this.selectedItems[item.value] = CHECKBOX_STATES.CHECKED;
        else if (item.inverse)
          this.selectedItems[item.value] = CHECKBOX_STATES.OPPOSITE;
      });
    }

    items?.forEach(item => {
      this.updateParentIndeterminateState();
    });
  }

  initializeItems(items: ItemModel[]) {
    items.forEach(item => {
      this.selectedItems[item.value] = this.selectedItems[item.value] || CHECKBOX_STATES.UNCHECKED;
      if (item.subItems) {
        this.initializeItems(item.subItems);
      }
    });
  }

  updateFilterText(value: string) {
    this.filterText = value;
    this.onFilterChange();
  }

  onParentChange(itemValue: string, checkbox: MatCheckbox) {
    let nextState = (this.selectedItems[itemValue] + 1) % 3;
    if (this.inderteminateDisabled) {
      if (nextState === CHECKBOX_STATES.OPPOSITE) {
        nextState = CHECKBOX_STATES.UNCHECKED;
      }
    }
    this.selectedItems[itemValue] = nextState;
    if (nextState === CHECKBOX_STATES.UNCHECKED) {
      checkbox.checked = false;
    }


    const item = this.findItem(this.parentItems, itemValue);
    if (item?.subItems) {
      this.updateChildrenSelection(item.subItems, nextState);
    }

    this.updateParentIndeterminateState();

    this.valueChange.emit(this.getSelectedValues());
  }

  onChildValueChange(parentItemValue: string, updatedItems: ItemModel[]) {
    this.updateParentIndeterminateState();
    this.valueChange.emit(this.getSelectedValues());
  }

  onFilterChange(): void {
    if (!this.filterText.trim()) {
      this.filteredItems = [...this.items];
      if (this.searchable) {
        this.expandedItems = {};
      }
    } else {
      this.filteredItems = this.filterItems(this.items, this.filterText);
    }
  }

  updateParentIndeterminateState() {
    this.parentItems.forEach(item => this.updateParentStateRecursively(item));
  }

  updateParentStateRecursively(item: ItemModel) {
    if (item.subItems && item.subItems.length > 0) {
      item.subItems?.forEach(subItem => {
        if (subItem.subItems) {
          this.updateParentStateRecursively(subItem);
        }
      });

      const allInverse = item.subItems.every(subItem => this.selectedItems[subItem.value] === CHECKBOX_STATES.OPPOSITE);
      const someSelected = item.subItems.some(subItem => this.selectedItems[subItem.value] === CHECKBOX_STATES.CHECKED || this.selectedItems[subItem.value] === CHECKBOX_STATES.OPPOSITE);

      if (allInverse) {
        this.selectedItems[item.value] = CHECKBOX_STATES.OPPOSITE;
      } else if (someSelected) {
        this.selectedItems[item.value] = CHECKBOX_STATES.CHECKED;
      } else {
        this.selectedItems[item.value] = CHECKBOX_STATES.UNCHECKED;
      }
    }
  }


  updateChildrenSelection(subItems: SubItemModel[], state: number) {
    subItems.forEach(subItem => {
      this.selectedItems[subItem.value] = state;

      if (subItem.subItems) {
        this.updateChildrenSelection(subItem.subItems, state);
      }
    });
  }

  findItem(items: ItemModel[], value: string): ItemModel | undefined {
    for (const item of items) {
      if (item.value === value) {
        return item;
      }
      if (item.subItems) {
        const found = this.findItem(item.subItems, value);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  toggleExpand(itemValue: string) {
    this.expandedItems[itemValue] = !this.expandedItems[itemValue];
  }

  getSelectedValues(): ItemModel[] {
    return this.items
      .filter(item => this.selectedItems[item.value] ||
        (item.subItems && this.hasSelectedChildren(item.subItems)))
      .map(item => ({
        name: item.name,
        value: item.value,
        label: item.label,
        inverse: this.selectedItems[item.value] === CHECKBOX_STATES.OPPOSITE ? true : false,
        subItems: item.subItems
          ? this.getSelectedValuesForChildren(item.subItems)
          : []
      }));
  }

  getSelectedValuesForChildren(subItems: SubItemModel[]): SubItemModel[] {
    return subItems
      .filter(subItem => this.selectedItems[subItem.value] ||
        (subItem.subItems && this.hasSelectedChildren(subItem.subItems)))
      .map(subItem => ({
        name: subItem.name,
        value: subItem.value,
        label: subItem.label,
        inverse: this.selectedItems[subItem.value] === CHECKBOX_STATES.OPPOSITE ? true : false,
        subItems: subItem.subItems
          ? this.getSelectedValuesForChildren(subItem.subItems)
          : []
      }));
  }

  hasSelectedChildren(subItems: SubItemModel[]): boolean {
    return subItems.some(subItem => this.selectedItems[subItem.value] ||
      (subItem.subItems && this.hasSelectedChildren(subItem.subItems)));
  }

  shouldDisplayItem(itemValue: string): boolean {
    return (
      (this.selectedItems[itemValue] === CHECKBOX_STATES.OPPOSITE ||
       this.selectedItems[itemValue] === CHECKBOX_STATES.CHECKED && this.displayOnlySavedValues && this.disabled) ||
      (!this.displayOnlySavedValues || !this.disabled)
    );
  }

  isCompleteSubLevel(item: ItemModel): boolean {
    if (!item.subItems || item.subItems.length === 0) {
      return this.selectedItems[item.value] === CHECKBOX_STATES.CHECKED;
    }
    return item.subItems.every(subItem => this.selectedItems[subItem.value] === CHECKBOX_STATES.CHECKED);
  }

  isPartiallySubLevel(item: ItemModel): boolean {
    if (!item.subItems) {
      return this.selectedItems[item.value] === CHECKBOX_STATES.OPPOSITE;
    }

    if (this.isCompleteSubLevel(item) && this.inderteminateDisabled) return false;

    const someItems = item.subItems.some(subItem => this.selectedItems[subItem.value] === CHECKBOX_STATES.CHECKED);
    return someItems;
  }

  private filterItems(items: ItemModel[], filterValue: string): ItemModel[] {
    const filterValueRegex = new RegExp(filterValue, 'i');

    const filterRecursively = (item: ItemModel, level: number = 0, parentMatch: boolean = false): ItemModel | null => {
      const clonedItem = JSON.parse(JSON.stringify(item));
      const matchesLabel = filterValueRegex.test(clonedItem.label);

      let hasMatchingDescendant = false;
      if (clonedItem.subItems && clonedItem.subItems.length > 0) {
        clonedItem.subItems = clonedItem.subItems
          .map((subItem: ItemModel) => filterRecursively(subItem, level + 1, matchesLabel || parentMatch))
          .filter((subItem: ItemModel) => subItem !== null);
        hasMatchingDescendant = clonedItem.subItems.length > 0;
      }

      const shouldKeep = matchesLabel || hasMatchingDescendant;

      if (shouldKeep) {
        if (matchesLabel) {
          if (level === 1) {
            this.expandedItems[clonedItem.value] = false;
          } else if (level === 0) {
            this.expandedItems[clonedItem.value] = false;
          } else {
            this.expandedItems[clonedItem.value] = level <= 2;
          }
        } else if (hasMatchingDescendant) {
          this.expandedItems[clonedItem.value] = true;
        }
      } else {
        delete this.expandedItems[clonedItem.value];
      }

      return shouldKeep ? clonedItem : parentMatch ? clonedItem : null;
    };

    return items
      .map(item => filterRecursively(item, 0))
      .filter((item): item is ItemModel => item !== null);
  }
}
