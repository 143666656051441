import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { FunnelComponent } from "../../../custom/funnel/funnel.component";
import { FilterModel, FunnelItemModel, FunnelModel } from '../../../../models/funnel-model';
import { CommonModule } from '@angular/common';
import { SpinnerService } from '../../../../services/core/spinner/spinner.service';
import { FunnelService } from '../../../../services/funnel/funnel.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MetaService } from '../../../../services/meta/meta.service';
import { SliderComponent } from "../../../custom/slider/slider.component";
import { ItemModel } from '../../../../models/custom/tree-view-model';
import { TreeViewComponent } from "../../../custom/tree-view/tree-view.component";
import { HintlySelectComponent } from '../../../custom/hintly-select/hintly-select.component';
import { CriteriaPositionComponent } from "../../../custom/criteria-position/criteria-position.component";
import { FunnelFormSorting } from '../../../../models/funnel-management-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-funnel-management',
  standalone: true,
  imports: [CommonModule, FunnelComponent, FormsModule,
    ReactiveFormsModule, MatCheckboxModule, SliderComponent,
    TreeViewComponent, HintlySelectComponent, CriteriaPositionComponent,
    RouterModule
  ],
  templateUrl: './funnel-management.component.html',
  styleUrl: './funnel-management.component.scss'
})
export class FunnelManagementComponent implements OnInit {

  funnelId: any;
  funnelForm!: FormGroup;
  industries!: ItemModel[];
  platforms!: ItemModel[];
  socialMedia!: ItemModel[];
  ads!: ItemModel[];
  emailSystemList!: ItemModel[];
  crmSystemList!: ItemModel[];
  ecommerceCmsList!: ItemModel[];
  companyTypeList!: ItemModel[];
  numberOfCriterias: number = 5;
  public sortingValues: WritableSignal<FunnelFormSorting> = signal({
    is_ecommerce: null,
    has_marketing_department: null,
    platform: null,
  });
  public availablePositions: WritableSignal<{ index: number, available: boolean }[]> = signal([
    { index: 1, available: false },
    { index: 2, available: true },
    { index: 3, available: false },
    { index: 4, available: false },
    { index: 5, available: false }
  ]);

  lastYear: number = new Date().getFullYear();

  constructor(
    private spinnerService: SpinnerService,
    private funnelService: FunnelService,
    private metaService: MetaService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder) {
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.updateSortingValues = this.updateSortingValues.bind(this);
  }
  private snackBar = inject(MatSnackBar);
  funnel!: FunnelModel;
  savedAdvancedCriterias: FunnelItemModel[] = [];

  ngOnInit(): void {
    this.setupForm();
    this.loadFilters();
    this.loadFunnel();
    this.setupSortingValues();
  }

  setupForm() {
    this.funnelForm = this.fb.group({
      name: ['', Validators.required],
      is_favorite: false,
      industry: [[], Validators.required],
      gross_results: [''],
      geography: [''],
      number_of_employees: [''],
      is_ecommerce: [''],
      has_marketing_department: [''],
      platform: [[]],
      ads: [[]],
      social_media: [[]],
      email_system: [[]],
      crm_system: [[]],
      ecommerceCms: [[]],
      company_type: [[]],
      incorporation_date: [''],
      no_unique_visitors_avg_3mo: [''],
      sorting: {
        is_ecommerce: null,
        has_marketing_department: null,
        platform: null,
        ads: null,
        social_media: null,
        email_system: null,
        crm_system: null,
        ecommerceCms: null,
        company_type: null,
        incorporation_date: null,
        no_unique_visitors_avg_3mo: null
      }
    });
  }

  loadFunnel() {
    this.funnelId = this.route.snapshot.paramMap.get('id');
    if (this.funnelId) {
      this.funnelService.getFunnel(this.funnelId).subscribe(data => {
        this.funnel = data;
      });
      this.updateFormWithFunnelData();
    } else {
      this.funnel = {
        name: '',
        is_favorite: false,
        criterias: [{
          feature: 'standard',
          description: 'Companies after standard filtering',
          counter: 0,
          position: 1,
          filters: []
        }],
        countQuery: null
      };
    }
  }

  updateFormWithFunnelData() {
    if (this.funnel) {
      this.funnelForm.patchValue({
        name: this.funnel.name,
      });
    }
  }

  loadFilters() {
    this.getIndustryFilter();
    this.getPlatformFilter();
    this.getAdsFilter();
    this.getSocialMediaFilter();
    this.getEmailSystemFilter();
    this.getCrmSystemFilter();
    this.getEcommerceCmsFilter();
    this.getCompanyTypeFilter();
  }

  setupSortingValues() {
    const sorting = this.funnelForm.get('sorting')?.value;
    this.sortingValues.set(sorting);
  }

  getIndustryFilter() {
    this.metaService.getIndustryList().subscribe(data => {
      this.industries = data;
    });
  }

  getPlatformFilter() {
    this.metaService.getPlatformList().subscribe(data => {
      this.platforms = data;
    });
  }

  getAdsFilter() {
    this.metaService.getAdsList().subscribe(data => {
      this.ads = data;
    });
  }

  getSocialMediaFilter() {
    this.metaService.getSocialMediaList().subscribe(data => {
      this.socialMedia = data;
    });
  }

  getEmailSystemFilter() {
    this.metaService.getEmailSystemList().subscribe(data => {
      this.emailSystemList = data;
    });
  }

  getCrmSystemFilter() {
    this.metaService.getCrmSystemList().subscribe(data => {
      this.crmSystemList = data;
    });
  }

  getEcommerceCmsFilter() {
    this.metaService.getEcommerceCmsList().subscribe(data => {
      this.ecommerceCmsList = data;
    });
  }

  getCompanyTypeFilter() {
    this.metaService.getCompanyTypeList().subscribe(data => {
      this.companyTypeList = data;
    });
  }

  async updateIndustryCriteria() {
    let shortDesc = 'Companies after standard filtering';
    let index = this.getCriteriaByKey('standard');
    this.initCriteria(index, 'standard', shortDesc);
    this.funnel.criterias[index].description = shortDesc;
    let industries = this.funnelForm.get('industry')!.value as any[];
    const industryNames = industries.map(industry => industry.label);
    let filter = this.getFilterByName(this.funnel.criterias[index], 'industry');
    if (filter == null) {
      const filters = this.funnel.criterias[index].filters;
      filter = { name: 'industry', values: industryNames };
      filters.push(filter);
    } else {
      filter.values = industryNames;
    }
    await this.refreshAllCriterias(index);
  }

  async updateStandardRange(key: string, min: string, max: string) {
    let shortDesc = 'Companies after standard filtering';
    let index = this.getCriteriaByKey('standard');
    this.initCriteria(index, 'standard', shortDesc);
    this.funnel.criterias[index].description = shortDesc;
    let filter = this.getFilterByName(this.funnel.criterias[index], key);
    const values = [min, max];
    if (filter == null) {
      const filters = this.funnel.criterias[index].filters;
      filter = { name: key, values: values };
      filters.push(filter);
    } else {
      filter.values = values;
    }
    await this.refreshAllCriterias(index);
  }

  async updateRangeCriteria(key: string, min: string, max: string) {
    if (key == 'number_of_employees' || key == 'gross_results') {
      return await this.updateStandardRange(key, min, max);
    }
    let index = this.getCriteriaByKey(key);
    let shortDesc = `Companies left after "${key}"`;
    this.initCriteria(index, key, shortDesc);
    let filters = this.funnel.criterias[index].filters;
    if (filters.length == 0) {
      filters.push({ name: key, values: [min, max] });
    }
    filters[0] = { name: key, values: [min, max] };
    this.updateSavedAdvancedCriterias(key);
    await this.refreshAllCriterias(index);
  }

  async updateCheckboxCriteria(event: any, key: string) {
    let index = this.getCriteriaByKey(key);
    let shortDesc = `Companies left after "${key}"`;
    this.initCriteria(index, key, shortDesc);
    this.initializeSortingValue(key);
    if (!this.funnel.criterias[index]) {
      this.initCriteria(index, key, shortDesc);
    }
    let filters = this.funnel.criterias[index].filters;
    if (filters.length == 0) {
      filters.push({ name: key, values: [event.target?.checked] });
    }
    filters[0] = { name: key, values: [event.target?.checked] };
    this.updateSavedAdvancedCriterias(key);
    await this.refreshAllCriterias(index);
  }

  async updateChecklistCriteria(key: string, models: ItemModel[], trueValue: any) {
    let index = this.getCriteriaByKey(key);
    let shortDesc = `Companies left after "${key}"`;
    this.initCriteria(index, key, shortDesc);
    this.initializeSortingValue(key);
    const values: any[] = [];
    for (const model of models) {
      values.push({name: model.name, value : trueValue});
    }
    this.funnel.criterias[index].filters = [];
    this.funnel.criterias[index].filters.push({ name: key, values: values });
    this.updateSavedAdvancedCriterias(key);
    await this.refreshAllCriterias(index);
  }

  orderCriteriaByPosition() {
    const sorting = this.funnelForm.get('sorting')?.value;
    this.funnel.criterias.forEach((criteria, index) => {
      criteria.position = sorting[criteria.feature];
    });
    const criteriasSorted = this.funnel.criterias.sort((a, b) => a.position - b.position);
    const criteriasFiltered = criteriasSorted.filter(criteria => criteria.position !== null);
    this.funnel.criterias = criteriasFiltered;
  }

  async refreshAllCriterias(start: number = 0) {
    for (let i = start; i < this.funnel.criterias.length; i++) {
        const { response, query } = this.funnelService.countFunnelMatches(this.funnel.criterias.slice(0, i + 1));
        let count: number = await response.toPromise();
        this.funnel.criterias[i].counter = isNaN(count) ? 0 : count;
        this.funnel.countQuery = query;
    }
  }

  initCriteria(index: number, key: string, description: string): void {
    let criteriaIndex = index;
    if (key === 'standard') {
      criteriaIndex = 0;
    } else if (criteriaIndex === 0) {
      criteriaIndex = 1;
    } else if (criteriaIndex >= 5) {
      criteriaIndex = 5;
    }
    const criteria = {
      feature: key,
      description: description,
      counter: 0,
      position: index + 1,
      filters: []
    };
    this.funnel.criterias[criteriaIndex] = criteria;
  }

  getCriteriaByKey(key: string): number {
    for (let i = 0; i < this.funnel.criterias.length; i++) {
      if (this.funnel.criterias[i]?.feature == key) {
        return i;
      }
    }
    return this.funnel.criterias.length >= 5 ? 4 : this.funnel.criterias.length;
  }

  getFilterByName(criteria: FunnelItemModel, filterName: string): FilterModel | null {
    for (const filter of criteria.filters) {
      if (filter.name == filterName) {
        return filter;
      }
    }
    return null;
  }

  async sliderValueChange(event: { min: string; max: string }, key: string, isAdvancedFeature?: boolean) {
    this.funnelForm.get(key)?.patchValue(event.min + ':' + event.max);
    if (isAdvancedFeature) {
      this.initializeSortingValue(key);
    }
    await this.updateRangeCriteria(key, event.min, event.max);
  }

  async setCheckboxList(event: ItemModel[], key: string) {
    this.funnelForm.get(key)?.patchValue(event);
    switch (key) {
      case 'industry':
        await this.updateIndustryCriteria();
        break;
      case 'social_media':
        await this.updateChecklistCriteria(key, event, {'$exists': true});
        break;
      default:
        await this.updateChecklistCriteria(key, event, true);
        break;
    }
  }

  initializeSortingValue(key: string) {
    const sorting = this.funnelForm.get('sorting')?.value;
    const advancedPositions = [2, 3, 4, 5];
    const nextPositions = advancedPositions.filter(position => !Object.values(sorting).includes(position));
    const nextPosition = nextPositions[0] || 5;
    const range = [];
    for (let i = 2; i <= nextPosition; i++) {
      range.push({
        index: i,
        available: true
      });
    }

    for (let i = nextPosition + 1; i <= 5; i++) {
      range.push({
        index: i,
        available: false
      });
    }

    this.availablePositions.set(range);

    if (sorting[key] === null) {
      sorting[key] = nextPosition;
      this.handlePositionChange(nextPosition, key);
    }
  }

  async save() {
    // TODO - Add proper form validation, for now just mark all fields as touched
    this.spinnerService.show();
    this.funnelForm.markAllAsTouched();
    this.funnel.name = this.funnelForm.get('name')!.value;
    this.setAdvancedFilters();
    if (this.funnel.criterias.length !== 5) {
      this.spinnerService.hide();
      this.snackBar.open("Please fill all 5 criteria levels");
      return;
    }

    if (!this.funnelId) {
      this.funnelService.create(this.funnel).subscribe({
        next: (response) => {
          this.spinnerService.hide();
          this.router.navigate(['/dashboard']);
        },
        error: (error) => {
          this.spinnerService.hide();
          this.snackBar.open("One error occured: " + error.error.data);
        },
      });
    }
  }

  setAdvancedFilters() {
    if (this.funnel.criterias?.[1]) {
      if (!this.funnel.criterias[1]?.filters || this.funnel.criterias[1]?.filters?.length == 0)
        this.funnel.criterias[1].filters = [];

      this.funnel.criterias[1].filters.push({ name: 'industry-advanced', values: this.funnelForm.get('industry')?.value });
    }
  }

  getStandardIndustries(): any {
    const values = this.funnelForm.get('industry')?.value.map((item: { name: any; value: any; }) => ({
      name: item.name,
      value: item.value
    }));
    return values;
  }

  async handlePositionChange(index: number, filterName: string) {
    const sorting = this.funnelForm.get('sorting')?.value;
    const oldFilterIndex = sorting[filterName];
    const existingCriteria = this.funnel.criterias.find((criteria) => criteria.feature === filterName);
    const oldCriteriaInPosition = this.funnel.criterias.find((criteria) => criteria.position === index);
    if (oldFilterIndex) {
      if (existingCriteria) {
        this.funnel.criterias[index - 1] = existingCriteria;
      }
    }

    if (oldCriteriaInPosition && oldCriteriaInPosition.feature !== filterName) {
      const oldCriteriaIndex = this.funnel.criterias.indexOf(oldCriteriaInPosition);
      this.funnel.criterias.splice(oldCriteriaIndex, 1);
    }

    Object.entries(sorting).forEach(([key, value]) => {
      if (value === index) {
        sorting[key] = null;
      }
    });

    sorting[filterName] = index;
    this.funnelForm.patchValue({ sorting });
    this.updateSortingValues(this.funnelForm.get('sorting')?.value);
    const savedCriteria = this.savedAdvancedCriterias.find((criteria) => criteria.feature === filterName);
    if (savedCriteria) {
      this.funnel.criterias[index - 1] = savedCriteria;
    }
    await this.refreshAllCriterias();
  }

  updateSortingValues(newSortingValues: any) {
    this.sortingValues.set(newSortingValues);
  }

  allCriteriasFilled(): boolean {
    return this.funnel.criterias.length === 5;
  }

  updateSavedAdvancedCriterias(key: string) {
    if (key === 'standard') {
      return;
    }
    const savedCriteria = this.savedAdvancedCriterias.find((criteria) => criteria.feature === key);
    if (savedCriteria) {
      const savedCriteriaIndex = this.savedAdvancedCriterias.indexOf(savedCriteria);
      const funnelCriteria = this.funnel.criterias.find((criteria) => criteria.feature === key);
      if (funnelCriteria) {
        this.savedAdvancedCriterias[savedCriteriaIndex] = funnelCriteria;
      }
    } else {
      const funnelCriteria = this.funnel.criterias.find((criteria) => criteria.feature === key);
      if (funnelCriteria) {
        this.savedAdvancedCriterias.push(funnelCriteria);
      }
    }
  }
}
