import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth-guard';
import { LoginComponent } from './components/auth/login/login.component';
import { HomeComponent } from './components/public/home/home.component';
import { DashboardComponent } from './components/private/funnel/dashboard/dashboard.component';
import { FunnelManagementComponent } from './components/private/funnel/management/funnel-management.component';

export const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'login', component: LoginComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [authGuard] },
    { path: 'funnel-management/:id', component: FunnelManagementComponent, canActivate: [authGuard] },
    { path: 'funnel-management', component: FunnelManagementComponent, canActivate: [authGuard] },
];
