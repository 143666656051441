import { Component, HostListener } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { CommonModule } from '@angular/common';
import { SpinnerService } from './services/core/spinner/spinner.service';
import { SpinnerComponent } from './components/custom/spinner/spinner.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, SpinnerComponent, FooterComponent, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  showFooter = true;
  private readonly hideFooterPaths = [/^\/funnel-management\/.*$/, '/funnel-management'];

  constructor(public spinnerService: SpinnerService, private readonly router: Router) {
    this.router.events.subscribe(event => {
      this.showFooter = !this.hideFooterPaths.some(pattern =>
        pattern instanceof RegExp ? pattern.test(this.router.url) : this.router.url.startsWith(pattern)
      );
    });
  }
  title = 'hintly-ui';

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: BeforeUnloadEvent): void {
    if (window.location.pathname === '/funnel-management') {
      event.preventDefault();
    }
  }
}
